<template>
  <div :class="classes">
    <div class="page-menu__body">
      <div class="rounded-md bg-white">
        <template v-for="group in groups">
          <div class="py-1 shadow-xs rounded mb-2">
            <router-link class="page-menu__item page-menu__parent-item" :to="{ name: group.route, params: {} }">
              <span>{{ group.label }}</span>
            </router-link>

            <template v-for="item in group.items">
              <router-link v-if="!item.hide" class="page-menu__item" :to="{ name: item.route, params: {} }">
                <span>- {{ item.label }}</span>
              </router-link>
            </template>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageNavigation',
  props: {
    position: {
      type: String,
      default: ''
    }
  },
  computed: {
    classes() {
      return [
        'page-menu',
        `as-position-${this.position}`
      ]
    },
    groups() {
      return [
        {
          label: 'Einkommen',
          key: 'accounting_income',
          route: 'accounting_income',
          items: [
            {
              label: 'Neues Einkommen',
              key: 'accounting_income_create',
              route: 'accounting_income_create',
            }
          ],
        },
        {
          label: 'Ausgaben',
          key: 'expenses',
          route: 'expenses',
        },
        {
          label: 'Abmelden',
          key: 'signOut',
          route: 'signOut',
        },
      ];
    }
  }
}
</script>

<style lang="scss">
.page-menu {
  height: calc(100% - 64px);
  overflow: auto;
  @apply p-8;

  &.as-position-fixed {
    position: fixed;
    width: 20%;
    left: 0;
    top: 32px;
  }
}

.page-menu__body {
  @apply origin-top-right;
  @apply right-0;
  @apply mt-2;
  @apply w-56;
  // @apply rounded-md;
  // @apply shadow-lg;
}

.page-menu__item {
  @apply block;
  @apply px-4;
  @apply py-2;
  @apply leading-5;
  @apply text-psi;

  // &.router-link-active {
  //   span {
  //     @apply bg-yellow-200;
  //   }
  // }
  //
  // &.router-link-exact-active {
  //   span {
  //     @apply bg-yellow-400;
  //   }
  // }
}
</style>
