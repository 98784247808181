import client from 'client'

export default {
  fetchBasket(basketId) {
    return client.get('/v1/basket', {
      headers: {
        "basket-id": basketId
      }
    })
  },
  addItem(payload) {
    return client.post('/v1/basket/item', payload.item, {
      headers: {
        "basket-id": payload.basketId
      },
    })
  },
  updateItem(payload) {
    return client.put(`/v1/basket/item/${payload.itemId}`, {
      ...payload.item
    }, {
      headers: {
        "basket-id": payload.basketId
      },
    })
  },
  removeItem(payload) {
    return client.delete(`/v1/basket/item/${payload.itemId}`, {
      headers: {
        "basket-id": payload.basketId
      }
    })
  },
}
