<template>
<Page>
  <div class="container mx-auto md:mt-32">
    <div class="mt-4 text-center">
      <div class="text-2xl">Diese Seite gibt es nicht</div>

      <router-link :to="{ name: 'shop' }">
        <Button class="mt-8" size="l">Jetzt zum Shop</Button>
      </router-link>
    </div>
  </div>
</Page>
</template>

<script>
export default {
  name: 'Error404'
};
</script>
