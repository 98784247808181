<template>
<div class="container mx-auto md:mt-32">
  <Headline>Registrieren</Headline>

  <Register @done="onRegistered"></Register>
</div>
</template>

<script>
import {
  mapActions,
  mapGetters
} from 'vuex';

export default {
  name: 'RegisterView',
  computed: {
    routeRef() {
      return this.$route.query.ref
    }
  },
  methods: {
    onRegistered() {
      this.$router.push({ name: 'shop' })
    }
  }
};
</script>
