<template>
  <div>
    <div class="container mx-auto md:mt-32 pb-64">
      <ActionMenu class="py-8" :groups="menu"></ActionMenu>

      <div class="px-2 md:flex- md:p-0">
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageLayoutAccount',
  computed: {
    menu() {
      return [{
        items: [{
            name: 'customer_orders',
            text: 'Bestellungen',
            route: {
              name: 'customer_orders'
            }
          },
          {
            name: 'customer_settings',
            text: 'Einstellungen',
            route: {
              name: 'customer_settings'
            }
          }
        ]
      }]
    },
  }
}
</script>
