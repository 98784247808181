import client from 'client';

export default {
  fetchOrders() {
    return client.get('/v1/user/orders');
  },
  resetPassword(payload) {
    return client.post('/v1/user/password/reset', payload);
  },
};
