<template>
  <div :class="classes">
    <img :src="imageSrc"/>
    <span class="ui-icon__sub-icon" v-if="subIcon">
      <img :src="subIconImageSrc"/>
    </span>
  </div>
</template>
<script>
export default {
  name: 'UiIcon',
  props: {
    icon: {
      type: String,
      default: 'basket'
    },
    subIcon: {
      type: String,
      default: ''
    },
  },
  computed: {
    classes() {
      return [
        'ui-icon',
      ];
    },
    imageSrc() {
      return `http://cdn-test.purdia.com/1.0/checkout/img/icon/${this.icon}.svg`;
    },
    subIconImageSrc() {
      return `http://cdn-test.purdia.com/1.0/checkout/img/icon/${this.subIcon}.svg`;
    }
  },
};
</script>

<style src="./icon.scss" lang="scss"></style>
