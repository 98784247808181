import accountChangePassword from './account-change-password.json'
import checkoutAdditionalData from './checkout-additional-data.json'
import checkoutBillingAddress from './checkout-billing-address.json'
import checkoutRegister from './checkout-register.json'
import checkoutShippingAddress from './checkout-shipping-address.json'
import login from './login.json'
import register from './register.json'
import adminConfigureFavIcon from './admin-configure-fav-icon.json'
import adminConfigureShopLogo from './admin-configure-shop-logo.json'
import adminConfigureShopIcons from './admin-configure-shop-icons.json'
import ACCOUNT_SETTINGS_DATA from './account-settings-data.json'

export default {
  accountChangePassword,
  checkoutAdditionalData,
  checkoutBillingAddress,
  checkoutRegister,
  checkoutShippingAddress,
  login,
  register,
  adminConfigureFavIcon,
  adminConfigureShopLogo,
  adminConfigureShopIcons,
  ACCOUNT_SETTINGS_DATA
}
