<template>
<div class="w-full h-auto" :ref="uniqueRef" @click.stop="onClick()">
  <div class="flex text-left justify-between relative">
    <div class="flex">
      <div class="mr-2" style="height: 100px;">
        <div class="h-full rounded-mds" v-if="image" :style="`background: url('${image.url}') center center / cover no-repeat;width: 82px;`"></div>
      </div>
      <div class="w-full">
        <div class="text-xs overflow-hidden">{{ name }}</div>
        <div class="flex justify-between">
          <div class="text-xs font-semibold">{{ _price }}</div>
        </div>

        <el-input-number style="width: 90px;" class="mt-2" size="mini" :min="1" v-model="model.quantity" @change="setQuantity"></el-input-number>
      </div>
    </div>
    <div class="w-24 flex justify-center items-center">
      <div @click.stop="onClickRemove" class="rounded-md hover:bg-gray-hover flex justify-center items-center" style="height: 48px;width: 48px;">
        <Icon icon="remove-item" :height="24"/>
      </div>
    </div>
    <div class="w-full text-left bottom-0" style="position: absolute;font-size: 13px;line-height: 18px;" v-if="error">
      <span class="bg-red-200 p-1 text-red-600">Konnte nicht entfernt werden</span>
    </div>
  </div>
</div>
</template>

<script>
import {
  mapActions
} from 'vuex'

export default {
  name: 'BasketItem',
  props: {
    itemId: {
      type: String
    },
    name: {
      type: String,
    },
    price: {
      type: Number,
    },
    _price: {
      type: String,
    },
    quantity: {
      type: Number,
    },
    image: {
      type: Object
    }
  },
  created() {
    this.model.quantity = this.quantity
  },
  methods: {
    ...mapActions({
      removeItem: 'basket/removeItem',
      updateItem: 'basket/updateItem'
    }),
    onClickRemove() {
      this.removeItem({
        basketId: this.$store.state.basket.basketId,
        itemId: this.itemId
      })
    },
    setQuantity(quantity) {
      console.log(quantity);
      this.updateItem({
        basketId: this.$store.state.basket.basketId,
        itemId: this.itemId,
        item: {
          quantity
        }
      });
    },
    onClick() {

    }
  },
  data() {
    return {
      animation: null,
      error: null,
      uniqueRef: 'item-' + Math.random(),
      model: {
        quantity: 0
      }
    }
  }
}
</script>
