<template>
  <nav class="o-nav w-sreen flex flex-wrap">
    <div class="nav-left w-full md:w-1/2">
      <div class="o-logo flex">
        <!--<img style="height: 54px;" src="https://image.flaticon.com/icons/svg/528/528103.svg">--><Headline size="l">perdia</Headline>
        <span>for freelancers</span>
      </div>
    </div>

    <div class="nav-right w-full md:w-1/2 text-right">
      <ul class="flex justify-end">
        <li class="mr-4" v-for="item in items">
          <router-link
            class="cursor-pointer inline-block font-medium"
            :to="{ name: item.route }">
            <component :is="item.component" v-bind="item.props">
              {{ item.name }}
            </component>
          </router-link>
        </li>
        <!-- <li class="mr-8">
          <router-link
            class="cursor-pointer inline-block font-medium"
            :to="{ name: 'income' }">
            Einnahmen
          </router-link>
        </li> -->
        <!-- <li class="mr-32">
          <router-link
            class="cursor-pointer inline-block font-medium"
            :to="{ name: 'dashboard' }">
            Dokumente
          </router-link>
        </li> -->

        <!-- <template v-if="authenticated">
          <el-dropdown :hide-on-click="true" trigger="click" >
            <span class="el-dropdown-link">
              jonas attiah<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <router-link to="/my-account" class="inline-block font-medium mr-5">
                  konto & einstellungen
                </router-link>
              </el-dropdown-item>
              <el-dropdown-item divided>
                <a @click="onSignOut()">abmelden</a>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
        <template v-else>
          <li>
            <router-link to="/auth" class="inline-block font-medium">
              Anmelden | Registrieren
            </router-link>
          </li>
        </template> -->
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Navigation',
  computed: {
    authenticated() {
      return true;
      // return this.$store.state.auth.authenticated;
    },
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    ...mapActions({
      signOut: 'auth/signOut',
    }),
    onSignOut() {
      this.signOut().then(() => {
        this.$router.push('auth');
      });
    },
  },
};
</script>

<style lang="scss">
</style>
