<template>
<div class="page page-checkout">
  <ComponentsBuilder schema="navigationCheckout"></ComponentsBuilder>

  <ComponentsBuilder schema="navigationCheckoutMobile"></ComponentsBuilder>

  <slot name="body"></slot>
</div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'PageCheckout',
  created() {
    this.fetchBasket()
  },
  methods: {
    ...mapActions({
      fetchBasket: 'basket/fetchBasket'
    })
  }
};
</script>
