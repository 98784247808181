<template lang="html">
  <div v-if="" class="o-countdown">
    <span>{{ minutes }}:</span><span>{{ seconds }}</span>
  </div>
</template>

<script>
export default {
  created() {
    this.startTimer(30);
  },
  data() {
    return {
      minutes: '',
      seconds: '',
    };
  },
  methods: {
    startTimer(duration) {
      let timer = duration; let minutes; let
        seconds;
      setInterval(() => {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? `0${minutes}` : minutes;
        seconds = seconds < 10 ? `0${seconds}` : seconds;

        this.minutes = minutes;
        this.seconds = seconds;

        if (--timer < 0) {
          timer = duration;
        }
        if (seconds == '00') {
          clearInterval();
        }
      }, 1000);
    },
  },
};
</script>
