<template>
<div :class="classes" @click="onToggle" v-click-outside="onHide">
  <div class="ui-dropdown__group">
    <img class="ui-dropdown__image" :src="image" v-if="image">
    {{ name }}
    <slot />
  </div>
  <!-- <DropdownBody v-if="show" :items="items" @click.stop="onClickItem"></DropdownBody> -->
  <div class="dropdown-body" v-if="showDropdown || alwaysShow">
    <template v-if="schema">
      <ComponentsBuilder :schema="schema"></ComponentsBuilder>
    </template>
    <ul class="w-full" v-if="items.length">
      <li class="ui-dropdown__item whitespace-no-wrap" :class="item.classes" v-for="item in items" @click="$emit('click', item.key)">
        <template v-if="item.component && item.component.key">
          <component :is="item.component.key" v-bind="item.component.attributes">
            <template v-if="item.component.slotContent">{{ item.component.slotContent }}</template>
          </component>
        </template>
        <template v-else-if="item.route">
          <router-link class="flex" :to="{ name: item.route }">
            <span class="w-6 h-6 mr-2"></span>

            <template v-if="item.t_name">{{ $t(item.t_name) }}</template>
            <template v-else>{{ item.name }}</template>
          </router-link>
        </template>
        <template v-else>
          <span class="w-6 h-6 mr-2"></span>

          <template v-if="item.t_name">{{ $t(item.t_name) }}</template>
          <template v-else>{{ item.name }}</template>
        </template>
      </li>
      <!-- <DropdownItem v-for="item in items" :key="item.key" :item="item" @click="$emit('click', item.key)"></DropdownItem> -->
    </ul>
  </div>
</div>
</template>

<script>
import Vue from 'vue';
import ClickOutside from 'vue-click-outside';

// const DropdownItem = Vue.component('dropdown-item', {
//   components: {
//     DropdownBody
//   },
//   props: {
//     item: {
//       type: Object,
//     },
//   },
//   template: `
//   <li class="ui-dropdown__item whitespace-no-wrap" @click="$emit('click', item.key)">
//     <span class="w-6 h-6 mr-2"></span> {{ item.name }}
//   </li>
//   `,
// })
//
// const DropdownBody = Vue.component('dropdown-body', {
//   components: {
//     DropdownItem
//   },
//   props: {
//     items: {
//       type: Array
//     },
//   },
//   template: `
//   <div class="dropdown-body">
//     <ul>
//       <DropdownItem v-for="item in items" :key="item.key" :item="item" @click="$emit('click', item.key)"></DropdownItem>
//     </ul>
//   </div>
//   `,
// })

const HEADLINE_PREFIX = 'ui-dropdown';

export default {
  name: 'Dropdown',
  props: {
    dropdownKey: {
      type: String,
      default: '',
    },
    name: {
      type: String,
    },
    image: {
      type: String,
    },
    items: {
      type: Array,
      default: () => [],
    },
    as: {
      type: String,
      default: '',
    },
    fixed: {
      type: Boolean,
    },
    component: {
      type: String,
    },
    showOnHover: Boolean,
    schema: String,
    alwaysShow: Boolean,
    size: {
      type: String,
      default: 'm'
    },
    position: {
      type: String,
      default: 'right'
    }
  },
  computed: {
    classes() {
      return [
        `${HEADLINE_PREFIX}`,
        this.showDropdown || this.alwaysShow && 'is-active',
        this.as && `as-${this.as}`,
        this.fixed && 'is-fixed',
        this.size && `as-size-${this.size}`,
        this.position && `as-position-${this.position}`
      ];
    },
  },
  methods: {
    show() {
      this.showDropdown = true;
    },
    hide() {
      this.showDropdown = false;
    },
    onHover() {
      if (this.showDropdownOnHover) {
        this.showDropdown = true;
      }
    },
    onToggle() {
      this.onClickItem(this.dropdownKey);

      this.showDropdown = !this.showDropdown;
    },
    onHide() {
      if (this.alwaysShow) {
        return
      }
      return this.hide();
    },
    onClickItem(key) {
      this.$emit('click', key);
    },
  },
  data() {
    return {
      showDropdown: false,
    }
  },
  directives: {
    ClickOutside,
  },
}
</script>

<style lang="scss">
.ui-dropdown {
    display: inline-block;
    position: relative;
    user-select: none;
    @apply px-2;
    @apply py-2;
    @apply rounded;

    &.is-active {
        @apply bg-gray-active;
    }

    &:hover {
        // @apply bg-gray-hover;
    }
    &:active {
        @apply bg-gray-active;
    }

    &.as-button {
        @apply bg-alpha;
        @apply border;
        @apply border-alpha-dark;
        @apply text-white;
    }

    .dropdown-body {
        display: flex;
        background: #fff;
        position: absolute;
        right: 0;
        left: auto;
        cursor: default;
        border-radius: 2px;
        @apply z-50;
        @apply shadow;
    }

    &.as-position-left {
      .dropdown-body {
        right: auto;
        left: 0;
      }
    }

    &.is-fixed {
        .dropdown-body {
            top: 72px;
            bottom: 0;
            left: 0;
            // right: 24px;
            position: fixed;
            width: calc(100% - 48px);
            height: calc(100% - 80px);
            overflow: auto;
            background-color: rgba(255, 255, 255, 0.9);
            backdrop-filter: blur(10px);
            margin: 0 24px;
        }
        &:hover {
            .dropdown-body {
                @apply shadow-lg;
            }
        }
    }

    &.as-fixed-fullsize {
        .dropdown-body {
            position: fixed;
            width: 100%;
        }
    }

    &.as-size-l {
      .ui-dropdown__item {
        line-height: 50px;
      }
    }

    .ui-dropdown__item {
        a {
          @apply text-primary;
        }
        &:hover {
          cursor: pointer;
          @apply bg-gray-hover;
        }
    }

    @media (min-width: 1024px) {
        &.is-fixed {
            // .dropdown-body {
            //   right: 16px;
            //   position: fixed;
            //   width: 25%;
            //   left: auto;
            //   height: 100%;
            // }
            .dropdown-body {
                width: 25%;
                left: auto;
                right: 24px;
                margin: 0;
            }
            &:hover {
                .dropdown-body {
                    @apply shadow-lg;
                }
            }
        }
    }
}

.ui-dropdown__group {
  display: flex;
  cursor: pointer;
  line-height: 32px;
}

.ui-dropdown__image {
    @apply h-8;
    @apply w-8;
    @apply rounded-full;
}

.ui-dropdown__item {
    display: flex;
    @apply py-1;
    @apply pr-6;
    &:hover {
        // @apply bg-gray-hover;
    }
    &.is-sticky-bottom {
        bottom: 0;
        position: sticky;
        background-color: rgba(255, 255, 255, 0.9);
        backdrop-filter: blur(10px);
    }
}
</style>
