<template>
<div :class="componentSchema.name + ' ' + componentSchema.classes" v-if="componentSchema">
  <template v-if="componentSchema.rows">
    <!-- Rows -->
    <div :class="row.classes" class="row" :style="row.style" v-for="row in componentSchema.rows">

      <!-- Cols in Row -->
      <template v-if="row.cols">
        <div :class="col.classes" class="col" :style="col.styles" v-for="col in row.cols">
          <component v-if="col.component && col.component.key" v-model="model[col.model]" :is="col.component.key" v-bind="col.component.attributes">
            <template v-if="col.component.slotContent"></template>
          </component>
          <template v-else>
            <!-- Rows in Col -->
            <div :class="colRow.classes" class="row" v-for="colRow in col.rows">
              <template v-if="colRow.cols">
                <div :class="col.classes" class="col" :style="col.styles" v-for="col in colRow.cols">
                  <component v-if="col.component && col.component.key" v-model="model[col.model]" :is="col.component.key" v-bind="col.component.attributes">
                    <template v-if="col.component.slotContent"></template>
                  </component>
                </div>
              </template>
            </div>
          </template>
        </div>
      </template>

      <!-- Rows in Row -->
      <template v-if="row.rows">
        <div :class="rowRow.classes" class="row" v-for="rowRow in row.rows">
          <template v-if="rowRow.cols">
            <div :class="col.classes" class="col" :style="col.styles" v-for="col in rowRow.cols">
              <component v-if="col.component && col.component.key" v-model="model[col.model]" :is="col.component.key" v-bind="col.component.attributes">
                <template v-if="col.component.slotContent"></template>
              </component>
            </div>
          </template>
        </div>
      </template>
    </div>
  </template>
</div>
</template>

<script>
import componentSchemas from '../config/components'

export default {
  name: 'ComponentsBuilder',
  model: {
    prop: 'value',
    event: 'update',
  },
  props: {
    schema: String,
    value: Object,
    json: String,
    errors: {
      type: Object,
      default: () => {}
    }
  },
  // created() {
  //   if (this.value) {
  //     this.model = this.value
  //   }
  // },
  watch: {
    'value': {
      handler() {
        // this.model = this.value
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    componentSchema() {
      if (this.json) {
        return this.json
      }

      return componentSchemas[this.schema]
    },
  },
  data() {
    return {
      model: {}
    }
  }
}
</script>
