<template>
<div class="checkout-step checkout-step-payment">
  <div class="w-1/2">
    <div class="text-2xl mt-4">Danke für deine Bestellung</div>

    <div class="mt-4">
      Do something here that will make the customer happy to come back
    </div>
  </div>

  <div class="flex mt-16">
    <div class="w-1/2">
      <!-- CTA: Submit order -->
      <router-link :to="{ name: 'shop' }">
        <Button size="l">Zurück zum Shop</Button>
      </router-link>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'CheckoutStepConfirmation',
};
</script>
