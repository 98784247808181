import client from 'client';

export default {
  register(payload) {
    return client.post('/users', payload);
  },
  login(payload) {
    const { email, password } = payload;

    const user = {
      email,
      password,
    };

    return client.post('/users/login', { user });
  },
  authenticate() {
    return client.get('/user');
  },
  requestPasswordReset(payload) {
    return client.post('/user/password/reset', payload);
  },
};
