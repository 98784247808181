<template>
  <div>
    <template v-for="item in items">
      <MailboxListItem :item="item" @click="$emit('click', item.id)"></MailboxListItem>
    </template>
  </div>
</template>

<script>
import MailboxListItem from './mailbox-list-item';

export default {
  name: 'MailboxList',
  components: {
    MailboxListItem,
  },
  props: {
    items: {
      type: Array
    }
  },
};
</script>
