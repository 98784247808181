<template>
<div class="checkout-step checkout-step-payment">
  <Headline class="mb-8">Zahlung</Headline>

  <List class="list-payment" @click="onSelect" :items="payment_methods_list" :selected="selected_payment_method" title="Option auswählen" shadow>
    <component ref="payment" v-if="!loading && payment && payment.initialization_data" :is="payment_method_component" :data="payment.initialization_data"></component>
  </List>

  <div class="mt-6" v-if="false">
    <label>Rabattcode</label>
    <div class="flex">
      <OInput v-model="model.voucherCode" schema-key="voucher-code" />
      <!-- <Button class="ml-2">Code Anwenden</Button> -->
    </div>
  </div>

  <div class="text-red-600 mt-8" v-if="error">
    {{ $t(`errors.${error}.message`) }}
  </div>

  <Button class="checkout-confirmation__submit mt-16" @click="onSubmit()" :loading="submitting || loadingPaymentAuthorization" size="l" :disabled="isConfirmationDisabled">Zahlungspflichtig bestellen</Button>


  <div v-if="submitting" class="fixed left-0 top-0 h-full w-full z-20 flex items-center justify-center opacity-0-" style="background-color: rgba(255, 255, 255, 0.3);backdrop-filter: blur(2px);">
    <div ref="modalFrame" class="rounded-md z-10 p-8 transform scale-75 opacity-0-" style="height: auto;background-color: rgba(255, 255, 255, 0.9);backdrop-filter: blur(10px);">
      <Loading/>
      <div class="text-4xl mb-4 font-bold">Deine Bestellung wird abgeschlossen</div>
    </div>
  </div>
</div>
</template>

<script>
import _ from 'lodash'
import {
  mapActions,
  mapGetters
} from 'vuex'
import PaymentOptionKlarna from '../../../components/payment-options/klarna'
import PaymentOptionStripe from '../../../components/payment-options/stripe'

const model = {
  payment: null,
  voucherCode: null
}

export default {
  name: 'CheckoutStepPayment',
  components: {
    PaymentOptionKlarna,
    PaymentOptionStripe
  },
  created() {
    this.fetchPayment().then(() => {
       this.setPreferedPaymentMethod()
    })
  },
  computed: {
    ...mapGetters({
      order: 'order/getOrder',
      paymentOptions: 'payment/getPaymentOptions',
      getVoucherCode: 'order/getVoucherCode',
      payment: 'order/getPaymentOption',
      orderBillingAddress: 'order/getOrderBillingAddress'
    }),
    payment_methods_list() {
      return this.paymentOptions.map(payment_option => ({
        id: payment_option.key,
        title: this.$t(`paymentOptions.${payment_option.key}.name`),
        image: payment_option.assets.logo ? payment_option.assets.logo.url : null,
        classes: [
          'payment-options-list__item',
          'payment-options-list__item--' + payment_option.key,
          this.payment && this.error && this.payment.payment_key === payment_option.key ? 'error' : null
        ]
      }))
    },
    isConfirmationDisabled() {
      return this.loading || this.paymentMethodFailed || this.loadingPaymentAuthorization
    }
  },
  watch: {
    order_fetched() {
      this.setPreferedPaymentMethod()
    },
    getVoucherCode() {
      this.model.voucherCode = this.getVoucherCode.code
    },
    'payment': {
      handler() {
        if (!this.payment) {
          return
        }
        this.selected_payment_method = this.payment.payment_key
        if (this.payment.payment_group_key === 'klarna') {
          this.payment_method_component = 'PaymentOptionKlarna'
          return
        }
        if (this.payment.payment_key === 'stripe_cards') {
          this.payment_method_component = 'PaymentOptionStripe'
          return
        }
      },
      immediate: true,
      deep: true,
    }
  },
  methods: {
    ...mapActions({
      fetchPayment: 'payment/fetchPayment',
      setPaymentMethod: 'order/setPaymentMethod',
      confirmOrder: 'order/confirmOrder',
    }),
    onSelect(payment_method_key) {
      this.selectPaymentMethod(payment_method_key)
    },
    selectPaymentMethod(payment_key) {
      this.error = null
      if (this.payment && payment_key === this.payment.key && this.order.payment_option) {
        console.warn('The payment method you clicked on is already selected');
        return
      }
      this.paymentMethodFailed = false

      this.payment = null

      this.loading = true

      this.setPaymentMethod({
        payment_key
      }).then((response) => {
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    setPreferedPaymentMethod() {
      if (this.order && !this.order.payment_option && !_.isEmpty(this.payment)) {
        this.selectPaymentMethod(this.payment.key)
        return
      }
    },
    onSubmit() {
      if (this.isConfirmationDisabled) {
        console.warn('The confirmation is disabled');
        return
      }

      this.error = null

      if (!this.payment) {
        this.error = 'no_payment_option_selected'
        return
      }

      if (!this.payment.initialization_data) {
        this.submitting = true
        this.confirmOrder().then((response) => {
          this.onOrderConfirmationSuccess(response.data.id)
        })

        return
      }

      this.loadingPaymentAuthorization = true

      if (this.payment.payment_key === 'paypal') {
        this.submitting = true
        const vm = this
        this.confirmOrder().then((response) => {
          if (response.data.action_required) {
            window.location = response.data.payment_callback_url
            return
          }
          this.submitting = false
        }).catch(err => {
          this.onOrderConfirmationFail(err)
        })
        return
      }

      this.$refs.payment.confirm().then((result) => {
        this.submitting = true

        const payload = {
          payment: {
            authorization: result
          }
        }

        this.confirmOrder(payload).then((response) => {
          this.onOrderConfirmationSuccess(response.data.id)
        }).catch(err => {
          this.onOrderConfirmationFail('error')
        })
      }).catch(err => {
        this.onOrderConfirmationFail(err.key)
      })
    },
    onOrderConfirmationSuccess(order_id) {
      if (shopConfig.Features.CUSTOM_ORDER_CONFIRMATION_REDIRECT) {
        return this.$router.push({ name: shopConfig.Features.CUSTOM_ORDER_CONFIRMATION_REDIRECT.custom.route })
      }

      this.$router.push({ name: 'customer_order', params: { orderId: order_id } })
    },
    onOrderConfirmationFail(err) {
      this.submitting = false
      this.error = err
      this.paymentMethodFailed = true
      this.loadingPaymentAuthorization = false
    }
  },
  data() {
    return {
      model,
      selected_payment_method: null,
      error: null,
      loading: false,
      loadingPaymentAuthorization: false,
      paymentLoadingAnimation: null,
      submitting: false,
      payment_method_component: null,
      paymentMethodFailed: false
    }
  },
  };
  </script>

<style lang="scss">
  .list-payment {
    .list-item__text {
      display: flex;
      align-items: center;
    }
    .list-item__image {
      background-size: 57px !important;
      width: 60px;
      align-self: center;
    }
    .list__item {
      height: 64px;
    }
  }
</style>
