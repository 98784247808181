
<template>
   <!-- @mouseenter="onHover()" @mouseleave="onMouseLeave()" -->
<div class="product-item text-left justify-between cursor-pointer" ref="item" v-long-press="250" @click="onClick()">

  <div class="relative" style="height: 390px;">
    <div class="product-item__badge-group badge-group absolute z-10">
      <Badge :class="'badge--product badge--product-' + badge.key" :text="badge._text" v-for="badge in variant._badges"/>
    </div>
    <div
      class="product-item__image rounded-msd h-full relative flex items-center justify-center"
      :style="`background: #e8e8e8 url('${displayedImage ? displayedImage.url : ''}') center center / cover no-repeat;border-radius: 2px;`">
    </div>

    <!-- <div class="absolute bottom-0 left-0 w-full opacity-0 transform scale-75" ref="gallery">
      <div class="product-item__gallery border border-gray rounded-md p-1 m-auto w-auto inline-block flex overflow-auto" style="height: 50px;">
        <template v-for="image in images">
          <div @click.stop="displayedImage = image" class="border border-white hover:border-gray rounded-md h-full mx-1 cursor-pointer flex-none" style="width: 38px;">
            <div class="h-full w-full rounded-md" :style="`background:url('${image.url}');background-position: center;    background-size: cover;background-repeat: no-repeat;`"></div>
          </div>
        </template>
      </div>
    </div> -->

    <!-- <div class="absolute bottom-0 opacity-0 transform scale-75" ref="cart">
      <Button size="s" fullsize>Hinzufügen</Button>
    </div> -->
  </div>

  <!-- <div class="flex flex-center">
    <div class="product-item__gallery border border-gray rounded-md p-2 m-auto w-auto inline-block flex overflow-auto" style="height: 50px;">
      <template v-for="image in images">
        <div @click="displayedImage = image" class="border border-white hover:border-gray rounded-md h-full mx-1 cursor-pointer flex-none" style="width: 32px;">
          <div class="h-full w-full rounded-md" :style="`background:url('${image.url}');background-position: center;    background-size: cover;background-repeat: no-repeat;`"></div>
        </div>
      </template>
    </div>
  </div> -->

  <div class="mt-2">
    <div class="flex justify-between">
      <div>
        <div class="text-sm">{{ name }}</div>
        <div class="text-xs font-semibold">{{ variant._price }}</div>
      </div>
      <!-- A/B addToCart -->
      <!-- <div class="p-2" style="height: 48px;width: 48px;">
        <img style="height: 32px;" src="https://www.flaticon.com/premium-icon/icons/svg/2985/2985035.svg">
      </div> -->
    </div>
  </div>

  <div class="md:hidden">
    <Button @click.stop="onClickAddToBasket()" class="mt-2 rounded-full" size="s" fullsize :loading="loadingAddToBasket">In den Warenkorb</Button>
  </div>

  <div class="opacity-0 transform scale-75 z-10" ref="cart">
    <Button @click.stop="onClickAddToBasket()" class="mt-2 rounded-full" size="s" fullsize :loading="loadingAddToBasket">Hinzufügen</Button>
  </div>

  <!-- <div class="opacity-0 transform scale-75 z-10" ref="cart">
    <Button @click.stop="onClickAddToBasket()" class="mt-2 rounded-full" size="s" fullsize>Hinzufügen</Button>
  </div> -->

  <!-- <UiCard ref="preview">-</UiCard> -->
</div>
</template>

<script>
import {
  mapActions
} from 'vuex'
import {
  TimelineLite,
  Elastic
} from 'gsap'
import LongPress from 'vue-directive-long-press'

export default {
  name: 'ProductItem',
  props: {
    name: {
      type: String,
    },
    _price: {
      type: String,
    },
    quantity: {
      type: Number,
    },
    images: Array,
    variants: Array,
    productKey: String,
  },
  created() {
    this.displayedImage = this.images[0]
  },
  computed: {
    variant() {
      return this.variants[0]
    }
  },
  methods: {
    ...mapActions({
      addItemToBasket: 'basket/addItem',
    }),
    onClickAddToBasket(variantId) {
      this.loadingAddToBasket = true
      this.addItemToBasket({
        basketId: this.$store.state.basket.basketId,
        item: {
          variantId: this.variant.id,
          quantity: 1
        }
      }).then(() => {
        this.loadingAddToBasket = false
      })
    },
    onLongPressStart() {
      const {
        item,
        preview
      } = this.$refs
      const timeline = new TimelineLite()

      this.senderAnimation = timeline.to(item, 0.1, {
        scale: 0.97,
      }).to(preview, 0.1, {
        width: '600px'
      })
    },
    onLongPressStop() {
      const {
        item,
      } = this.$refs
      const timeline = new TimelineLite()

      this.senderAnimation = timeline.to(item, 0.2, {
        scale: 1,
      })

      // this.$emit('long-press-stopped')
    },
    onHover() {
      const {
        cart,
      } = this.$refs
      const timeline = new TimelineLite()

      this.galleryAnimation = timeline.to(cart, 0.2, {
        scale: 1,
        opacity: 1
      })

      if (window.shopConfig.Features.SHOP_CATEGORY_PRODUCT_PREVIEW_IMAGE_FLIP) {
        this.displayedImage = this.images[1]
      }
    },
    onMouseLeave() {
      this.galleryAnimation.reverse()

      if (window.shopConfig.Features.SHOP_CATEGORY_PRODUCT_PREVIEW_IMAGE_FLIP) {
        this.displayedImage = this.images[0]
      }
    },
    onClick() {
      this.$emit('click')
    }
  },
  data() {
    return {
      galleryAnimation: null,
      displayedImage: null,
      loadingAddToBasket: false
    }
  },
  directives: {
    'long-press': LongPress
  },
}
</script>

<style lang="scss">
.product-item {
    user-select: none;
}

.product-item__image {}

.product-item__gallery {
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);

    &::-webkit-scrollbar {
        display: none;
    }
}
</style>
