<template>
  <div class="ui-input-search">
    <!-- Option Selected Mode -->
    <template v-if="!isEditMode">
      <div @click="onEdit()">
        <OInput
          :value="getSelectedOption.name"
          v-bind="$attrs"
          disabled/>
      </div>
    </template>
    <!-- Search / Edit Mode -->
    <div v-show="isEditMode">
      <OInput v-model="model.searchTerm" v-bind="$attrs" @input="onSearch" @focus="$emit('focus')" ref="fieldSearchTerm"/>

      <div class="absolute border rounded-md mt-1 bg-white pb-2 pt-1 shadow-lg" v-if="model.searchTerm || results.length">
        <template v-if="results.length && !hideResults">
          <transition-group
            class="ui-input-search__results"
            v-bind:class="{'is-loading':loading}"
            name="vue-transition--list"
            tag="div"
            style="max-height: 342px;overflow: auto;">
            <div class="border-b mb-2" v-for="(option, index) in results" v-bind:key="index">
              <div
                class="px-4 py-3 hover:bg-yellow-400 cursor-pointer"
                v-bind:class="{'bg-yellow-200':selectedOption && option.refIndex===selectedOption.refIndex}"
                @click="onSelect(option.refIndex)">
                {{ option.item.name }}
              </div>
            </div>
          </transition-group>
        </template>

        <slot name="dropdown-bottom"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Fuse from 'fuse.js';

const model = {
  searchTerm: '',
}

export default {
  name: 'OInputSearchSelect',
  model: {
    prop: 'value',
    event: 'update',
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: '',
    },
    // searchTerm: {
    //   type: String,
    //   defaut: ''
    // },
    selectedOption: {
      type: String,
      defaut: ''
    },
    hideResults: {
      type: Boolean,
      defaut: false
    },
    loading: {
      type: Boolean,
      defaut: false
    },
  },
  data() {
    return {
      model: {
        searchTerm: ''
      },
      edit: false,
      results: [],
    }
  },
  computed: {
    isEditMode() {
      return this.edit || !this.selectedOption;
    },
    getSelectedOption() {
      if (this.selectedOption && this.options.length) {
        return this.options.find(option => option.id === this.selectedOption);
      }

      return {};
    }
  },
  methods: {
    search() {
      const list = this.options;

      const fuse = new Fuse(list, {
        keys: ['name']
      })

      this.results = fuse.search(this.model.searchTerm);

      this.$emit('update', this.model.searchTerm);
    },
    onSearch() {
      this.search();
    },
    onSelect(refIndex) {
      if (this.loading) {
        return;
      }

      this.edit = false;

      this.$emit('select', this.results.find(option => option.refIndex === refIndex).item.id);
    },
    onEdit() {
      this.edit = true;
      if (this.selectedOption) {
        this.model.searchTerm = this.getSelectedOption.name;
      }
    },
  }
};
</script>

<style lang="scss">
  .ui-input-search__results {
    position: relative;

    &.is-loading {

      &:after {
        content: '';
        height: 100%;
        width: 100%;
        background: #fff;
        opacity: 0.5;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
</style>
