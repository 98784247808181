<template>
<div class="loading">
  <div class="loader"></div>
</div>
</template>

<script>
export default {
  name: 'Loading',
};
</script>

<style scoped>
.loader {
  border: 1px solid #000;
  border-radius: 50%;
  border-top: 3px solid #fff;
  width: 25px;
  height: 25px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

.loader-- {
  border: 1px solid #e8e8e8;
  border-radius: 50%;
  border-top: 3px solid #737373;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
