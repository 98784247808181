import client from 'client';

export default {
  fetchCategories() {
    return client.get('/v1/products/categories');
  },
  fetchCategory(payload) {
    return client.get(`/products/categories/${payload.category}`);
  },
};
