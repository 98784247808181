<template>
  <div class="order">
    <div class="text-right">
      <router-link :to="{ name: 'customer_orders' }">Schließen</router-link>
    </div>


    <Headline class="stext-center mt-8">Deine Bestellung</Headline>

    <p class="stext-center"><span>Bestell-Nr.</span> #{{ order_id }}</p>
    <Badge :text="status.text" :type="status.type"/>

    <List class="order__downloads-list mt-8" v-if="items_downloads" :items="items_downloads" title="Bücher herunterladen" shadow @click="onClickDownload"/>

    <List class="mt-8" :items="items" title="Artikel Liste" shadow/>

    <div class="text-right mt-2">
      <div class="text-gray-text">Zwischensumme {{ total }}</div>
      <div class="text-gray-text">Versand 0,00 €</div>
      <div class="text-gray-text">MwSt. 0,00 €</div>
      <div class="flex justify-end mt-2 items-center">Gesamtsumme</div>
      <div class="font-semibold text-2xl ml-2">{{ total }}</div>
    </div>

    <div>Zahlungsart: {{ $t(`paymentOptions.${payment_option}.name`) }}</div>
  </div>
</template>

<script>
import {
  DownloadApi
} from 'api-client';

export default {
  name: 'Order',
  props: {
    status: Object,
    order_id: String,
    items: Array,
    total: String,
    payment_option: String,
    downloads: Array
  },
  computed: {
    items_downloads() {
      return this.downloads ? this.downloads.map(download => ({
        id: download.hash,
        title: download.file.name,
        // button: {
        //   text: 'Herunterladen'
        // },
        icon: 'book'
      })) : null
    }
  },
  methods: {
    onClickDownload(hash) {
      window.open(`${this.$config.api_url}/v1/download/${hash}`)
    },
    onDownload() {
      this.downloads.forEach(download => {
        DownloadApi.download({download})
      })
    }
  }
}
</script>

<style>
.order__downloads-list .list__item {
  align-items: center;
}
</style>
