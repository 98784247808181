import client from 'client';
import axios from 'axios';

const CancelToken = axios.CancelToken;
const fetchMailRequest = axios.CancelToken.source();

export default {
  fetchMailbox(payload) {
    return client.get(`/mailbox`);
  },
  fetchMails() {
    return client.get('/mailbox/mails');
  },
  fetchMail(payload) {
    return client.get(`/mailbox/${payload.mailId}`, {
      cancelToken: fetchMailRequest.token
    });
  },
  sendMail(payload) {
    return client.post('/mailbox/mail', payload);
  },
  fetchMailRequest,
};
