<template>
  <div class="text">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'Text',
  props: {
    text: String
  }
}
</script>
