import _ from 'lodash'
import {
  OrderApi
} from 'api-client';
import router from '../../../router'

const store = {
  namespaced: true,
  state: {
    orders: [],
    order: {},
  },
  getters: {
    getOrders: (state) => {
      return state.orders.reverse()
    },
    getOrder: (state) => {
      return state.order
    },
    getVoucherCode: (state) => {
      return state.order.voucherCode
    },
    getPaymentOption: (state, getters, rootState) => {
      if (state.order.payment) {
        return state.order.payment
      }
      /**
       * Summary: Set payment_method based on payment endpoint
       */
      if (rootState.payment.payment) {
        return rootState.payment.payment
      }

      return null
    },
    getOrderBillingAddress: (state) => {
      if (_.isEmpty(state.order.address.billing)) {
        return {
          ...state.order.address.shipping,
          isSameAsShippingAddress: true
        }
      }

      return state.order.address.billing
    }
  },
  mutations: {
    updateOrders: (state, payload) => {
      state.orders = payload
    },
    updateOrder: (state, payload) => {
      state.order = payload
    },
    clearOrder: (state, payload) => {
      state.order = {}
    },
  },
  actions: {
    fetchOrders: ({commit, dispatch, state}, payload) => new Promise((resolve, reject) => {
      OrderApi.fetchOrders(payload)
        .then((response) => {
          commit('updateOrders', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error.response)
        });
    }),
    fetchOrder: ({commit, dispatch, state}, payload) => new Promise((resolve, reject) => {
      OrderApi.fetchOrder(payload)
        .then((response) => {
          commit('updateOrder', response.data)
          commit('basket/setBasketSession', response.data.basket, { root: true })
          resolve(response)
        })
        .catch((error) => {
          reject(error.response)
        });
    }),
    createOrder: ({commit, dispatch, state}, payload) => new Promise((resolve, reject) => {
      OrderApi.createOrder(payload)
        .then((response) => {
          commit('updateOrder', response.data)
          if (response.data.user) {
            commit('auth/authenticate', response.data.user.token, { root: true });
            commit('user/updateUser', response.data.user, { root: true });
          }
          resolve(response)
        })
        .catch((err) => {
          if (err.response.data.user) {
            /**
             * Summary: Address invalid but user created
             *
             * Description: If address is invalid but user could be created, return the user and a "partial error"
             * The frontend should authenticate the new user even if the address is invalid
             */
            commit('auth/authenticate', err.response.data.user.token, { root: true });
            commit('user/updateUser', err.response.data.user, { root: true });
          }
          reject(err.response)
        });
    }),
    updateOrderData: ({commit, dispatch, state}, payload) => new Promise((resolve, reject) => {
      OrderApi.updateOrderData(payload)
        .then((response) => {
          commit('updateOrder', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err.response)
        });
    }),
    setPaymentMethod: ({commit, dispatch, state}, payload) => new Promise((resolve, reject) => {
      OrderApi.setPaymentMethod(payload)
        .then((response) => {
          commit('updateOrder', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        });
    }),
    confirmOrder: ({commit, dispatch, state}, payload) => new Promise((resolve, reject) => {
      OrderApi.confirmOrder(payload)
        .then((response) => {
          /**
           * Summary: Order confirmed
           *
           * Description: If status IS 201 THEN order IS confirmed
           */
          if (response.status === 201) {
            commit('clearOrder')
            commit('basket/deleteSession', null, { root: true })
          } else {
            /**
             * Summary: IF status NOT 201 THEN action required
             */
          }

          resolve(response)
        })
        .catch((error) => {
          reject(error)
        });
    }),
    fetchOrderConfirmationStatus: ({commit, dispatch, state}) => new Promise((resolve, reject) => {
      OrderApi.fetchOrderConfirmationStatus()
        .then((response) => {
          /**
           * Summary: Order confirmed
           *
           * Description: If status IS 201 THEN order IS confirmed
           */
          if (response.status === 201) {
            commit('clearOrder')
            commit('basket/deleteSession', null, { root: true })
          } else {
            /**
             * Summary: IF status NOT 201 THEN action required
             */
          }

          resolve(response)
        })
        .catch((error) => {
          reject(error.response)
        });
    }),
  },
};

export default store
