<template>
<div>
  <div :class="classes" @click="$emit('click')" @contextmenu-disabled.prevent="openMenu">

    <!-- <div class="bg-white rounded-md border border-gray p-1 px-2 font-bold whitespace-no-wrap flex-none mr-2" style="width: 34px;" v-if="!item.flags.seen"></div> -->
    <div class="flex overflow-hidden">
      <div class="relative" style="padding-top: 1px;">
        <span class="absolute left-0 top-0 rounded-full w-2 h-2 bg-danger z-10" v-if="!item.seen"></span>
        <Dropdown class="flex" :items="optionsSender" @click.prevent="onClickSender()">
          <span class="bg-gray rounded-full mr-2 h-8 w-8 overflow-auto whitespace-no-wrap" :style="`background: #e8e8e8 url('http://cdn.purdia.com/mail-senders/${item.sender.email}.svg');`"></span>
          <div style="line-height: 32px;">{{ item.sender.name }}</div>
        </Dropdown>
      </div>
      <div class="ml-3 overflow-hidden" style="line-height: 34px;">{{ item.subject }}</div>
    </div>
    <div class="border border-gray bg-white rounded-md px-1 h-8 my-1 mr-2" style="line-height: 30px;">{{ formatMailReceivedTime(item.date) }}</div>

    <div class="flex-1 flex justify-between pr-2" v-if="false">
      <div class="ml-3" style="line-height: 34px;">{{ item.subject }}</div>
      <div class="flex flex-none my-1 overflow-hidden">
        <Dropdown class="flex">
          <img src="https://media-exp1.licdn.com/dms/image/C4D03AQHdPPY3wt8D0A/profile-displayphoto-shrink_100_100/0?e=1602115200&amp;v=beta&amp;t=9veXmaP2n23MoE4SQXMl8xPziWV4IyGf9xaVBcXn7qY" class="h-8 w-8 rounded-full mr-2  ">
          <div style="line-height: 32px;">jonasattiah</div>
        </Dropdown>
        <div class="mailbox-list-item__sender-name flex bg-white rounded-full borders borders-gray px-2 whitespace-no-wrap" @mouseenter="onMouseOverSenderName()" @mouseleave="onMouseLeaveSenderName()">
          {{ item.sender.name }}
        </div>
      </div>
    </div>

    <slot />
  </div>

  <!-- <div class="w-full bg-gray ml-4" style="height: 1px;"></div> -->
  <!-- viewMenu -->
  <div id="right-click-menu" ref="item" v-if="viewMenu" @blur="closeMenu" class="dropdown-body absolute py-2 bg-white rounded left-0 shadow z-10" :style="`top:${top}; left:${left}`" v-click-outside="onHideOptions">
    <ul>
      <li class="py-1 pr-6 flex hover:bg-gray-200 whitespace-no-wrap"><span class="w-6 h-6 mr-2"></span> Delete</li>
      <li class="py-1 pr-6 flex hover:bg-gray-200 whitespace-no-wrap"><span class="w-6 h-6 mr-2"></span> Archive</li>
      <!-- <li class="py-1 pr-6 flex hover:bg-gray-200" @click="onClick()"><span class="w-6 h-6 mr-2"></span> Duplicate</li> -->
    </ul>
  </div>
  <!-- <ul id="right-click-menu" tabindex="-1" ref="item" v-if="viewMenu" @blur="closeMenu"  :style="`top:${top}, left:${left}`">
          <li>First list item</li>
          <li>Second list item</li>
      </ul> -->
</div>
</template>

<script>
import moment from 'moment';
import {
  TimelineLite
} from 'gsap'
import ClickOutside from 'vue-click-outside';

// let tl = gsap.timeline();
// tl.to(".mailbox-list-item__sender-name__text", {x: 40})
// .to(".mailbox-list-item__sender-name__text", {rotation:90}, "-=0.5");

export default {
  name: 'MailboxListItem',
  props: {
    item: {
      type: Object
    },
    size: {
      type: String,
      default: 'm'
    },
    active: Boolean
  },
  data() {
    return {
      viewMenu: false,
      top: '0px',
      left: '0px',
      rightClicked: false,
      senderAnimation: null,
    }
  },
  computed: {
    classes() {
      return [
        'mailbox-list-item justify-between',
        `as-size-${this.size}`,
        this.item.seen ? 'is-seen' : '',
        this.active ? 'is-active' : '',
        this.viewMenu ? 'is-right-clicked' : ''
      ];
    },
    optionsSender() {
      return [{
          name: 'Hinzufügen',
          key: 'add-contact',
        },
        {
            name: 'Verify',
            key: 'verify',
          }
      ];
    }
  },
  methods: {
    setMenu: function(top, left) {

      const largestHeight = window.innerHeight - this.$refs.item.offsetHeight - 25;
      const largestWidth = window.innerWidth - this.$refs.item.offsetWidth - 25;

      if (top > largestHeight) top = largestHeight;

      if (left > largestWidth) left = largestWidth;

      this.top = top + 'px';
      this.left = left + 'px';
    },
    closeMenu: function() {
      this.viewMenu = false;
    },
    openMenu: function(e) {
      this.viewMenu = true;

      this.$nextTick(function() {
        console.log(this.$refs);
        this.$refs.item.focus();
        this.setMenu(e.y, e.x)
      }.bind(this));
      e.preventDefault();
    },
    onHideOptions() {
      this.viewMenu = false;
    },
    onMouseOverSenderName() {
      const {
        sender,
        senderHide
      } = this.$refs
      const timeline = new TimelineLite()

      this.senderAnimation = timeline.to(sender, 0.1, {
        x: -4
      }).to(senderHide, 0.1, {
        opacity: 1
      })
      console.log('#');
      // tl.play();
    },
    onMouseLeaveSenderName() {
      this.senderAnimation.reverse();
    },
    onClickSender() {},
    formatMailReceivedTime(date) {
      // const isYesterday = moment(date).isSame(moment().subtract(1, "days"));
      const isToday = moment(date).isSame(new Date(), "day");
      const isYesterday = moment(date).isSame(moment().subtract(1, "days"), "day");

      if (isYesterday) {
        return 'Yesterday ' + moment(date).format('HH:mm');
      }
      if (isToday) {
        return moment(date).format('HH:mm');
      }

      return moment(date).format('DD.MM.YY');
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style lang="scss" scoped>
.mailbox-list-item {
    display: flex;
    background: #fff;
    white-space: nowrap;
    @apply pl-2;
    @apply py-2;
    @apply border-b;
    @apply border-gray;
    &:hover {
        cursor: pointer;
        @apply bg-gray-hover;
    }
    &.is-seen {
        @apply text-gray-text;
    }
    &.is-active {
        @apply bg-gray-active;
    }
    &.is-right-clicked {
        border: 1px solid;
    }
    &.as-size-s {
      @apply py-0;
    }
}
</style>
