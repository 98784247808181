<template>
  <div class="o-input-date">
    <OInput v-model="formattedDate" v-bind="$attrs"/>
    <el-date-picker v-model="model" type="date"></el-date-picker>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'OInputDate',
  model: {
    prop: 'model',
    event: 'update',
  },
  props: {
    model: {
      type: String,
      default: '',
    },
  },
};
</script>
