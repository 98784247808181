<template>
<div>
  <Dropdown class="action-menu__dropdown" :items="menu" :fixed="dropdownFixed">
    <img style="height: 32px;" src="https://www.flaticon.com/premium-icon/icons/svg/2984/2984969.svg">
    <span class="absolute right-0 bottom-0 rounded-full bg-danger z-10 text-white font-bold h-2 w-2 mr-5">
      <!-- {{ basket.quantityCount }} -->
    </span>
  </Dropdown>
</div>
</template>

<script>
export default {
  name: 'NavItemAdmin',
  props: {
    dropdownFixed: Boolean
  },
  computed: {
    menu() {
      return []
    },
  }
}
</script>
