<template>
  <component :is="route ? 'router-link' : 'div'" :class="`icon as-text-position-${text_position}`" :to="route ? { name: route.name, params: route.params } : {}">
    <img alt="Icon nicht verfügbar" :style="`height: ${height}px;`" :src="icon_url">
    <div class="icon__text">{{ text }}</div>
  </component>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    icon: String,
    height: {
      type: Number,
      default: 32
    },
    text: String,
    text_position: {
      type: String,
      default: 'after'
    },
    route: Object
  },
  computed: {
    icon_url() {
      if (window.shopConfig.Features.CUSTOM_ICONS) {
        const icons = window.shopConfig.Features.CUSTOM_ICONS.data
        const icon = icons.filter(icon => icon.key === this.icon)[0]

        return icon ? icon.url : ''
      } else {
        return ''
      }
    }
  }
}
</script>

<style>
.icon {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-flow: column;
  &.as-text-position-after {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    .icon__text {
      margin-left: 4px;
    }
  }
  &.as-text-position-above {
    display: flex;
    flex-flow: column-reverse;
  }
  &.as-text-position-before {
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
    .icon__text {
      margin-right: 4px;
    }
  }
}
</style>
