<template>
  <div class="list-wrapper">
    <div v-if="title" class="list__header">
      {{ title }}
    </div>

    <ul class="list as-vertical">
      <li v-for="item in items" :class="[{'active':selected && selected===item.id}, item.classes]">
        <component :is="item.route ? 'router-link' : 'div'" @click="onClick(item.id)" class="list__item" :to="item.route ? { name: item.route.name, params: item.route.params } : {}">
          <Icon class="mr-4" v-if="item.icon" :icon="item.icon"/>
          <!-- <img v-if="item.image" class="list-item__image" :src="item.image"> -->
          <div v-if="item.image" class="list-item__image" :style="`background: url('${item.image}') center center / cover no-repeat;`"></div>
          <div class="list-item__text">
            <div class="flex">{{ item.title }}</div>
            <div class="text-gray-text text-xs">{{ item.description }}</div>
            <Badge v-if="item.status" :type="item.status.type" :text="item.status.text"/>
            <ul v-if="item.additional_lines">
              <li v-for="line in item.additional_lines" :class="line.classes">
                {{ line.text }}
                <div v-html="line.html"></div>
              </li>
            </ul>
          </div>
          <Button v-if="item.button" :text="item.button.text" size="xs"/>
        </component>
        <slot v-if="selected === item.id"/>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'List',
  props: {
    title: String,
    items: Array,
    selected: String
  },
  methods: {
    onClick(id) {
      this.$emit('click', id)
    }
  }
}
</script>

<style>
.list-wrapper {
  width: 100%;
}

.list {
  background: #fff;
  cursor: default;
  border-radius: 2px;
  padding: 16px 0;
  box-shadow: var(--list-shadow);
}
.list.as-vertical {
  flex-wrap: wrap;
}

.list > li {
  width: 100%;
}
.list > li:hover {
  cursor: pointer;
  background: var(--color-gray-light);
}
.list > li.active {
  background: var(--color-gray-light);
}
.list > li.error {
  @apply border;
  @apply border-danger;
}
.list > li.router-link-active {
  background: var(--color-gray-light);
}

.list-item__text {
  flex: auto;
}

.list__item {
  display: flex;
  padding: 12px 16px;
  width: 100%;
}

.list-item__image {
  @apply mr-4;
  @apply ml-4;
  height: 68px;
  width: 68px;
  border-radius: 2px;
}
</style>
