<template>
  <span :class="classes">
    <slot/>
    {{ text }}
  </span>
</template>

<script>
export default {
  name: 'Badge',
  props: {
    text: String,
    type: {
      type: String,
      default: 'primary'
    }
  },
  computed: {
    classes() {
      return [
        'badge',
        'badge--' + this.type
      ]
    }
  }
}
</script>
