<template>
<div>
  <List :items="items"/>
</div>
</template>

<script>
export default {
  name: 'CustomerAccount',
  computed: {
    navigation() {
      return {
        title: {
          text: 'Account',
          size: 'M'
        }
      };
    },
    items() {
      return [
        {
          title: 'Bestellungen',
          description: 'Liste deiner Bestellungen',
          route: {
            name: 'customer_orders'
          }
        },
        {
          title: 'Einstellungen',
          description: 'Account, Adressen, Zahlung etc.',
          route: {
            name: 'customer_settings'
          }
        }
      ];
    }
  }
};
</script>
