<template>
  <div :class="'card ' + classes">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    classes: String
  }
}
</script>

<style>
  .card {
    height: 100%;
    width: 100%;
    background: #fff;
    padding: 16px;
    @apply shadow;
  }
</style>
