<template>
<div class="py-8">
  <div class="text-lg">Vasen & Kunstblumen</div>
  <ul>
    <li>Vasen</li>
  </ul>
</div>
</template>

<script>
export default {
  name: 'ShopCategoriesDropdown',
}
</script>

<style lang="css" scoped>
</style>
