<template>
<AdminPage>
  <template slot="body">
    <div class="container mx-auto pb-64 flex">
      <div class="w-2/3">
        <div class="relative">
          <OInput size="l" label="Suche" placeholder="Produkt finden.." class="mt-6"></OInput>
          <div v-if="false" class="origin-top-right mt-2 rounded-md shadow-lg absolute z-10" style="width: 500px;">
            <div class="rounded-md bg-white shadow-xs">
              <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <!-- <a
                  v-for="product in products.slice(0, 8)"
                  @click="onClickIncome(product.id)"
                  href="#"
                  class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
                  {{ product.name }}
                </a> -->
                <template v-for="product in products">
                  <div class="flex pl-2 py-2 border-b border-gray hover:bg-gray-active cursor-pointer">
                    <div class="w-full flex overflow-hidden">
                      <div class="relative" style="padding-top: 1px;">
                        <div class="absolute left-0 top-0 rounded-full w-2 h-2 bg-danger z-10" v-if="product"></div>
                        <Dropdown class="flex" style="padding: 0;">
                          <div class="bg-gray rounded-sfull mr-2 h-12 w-10 overflow-auto whitespace-no-wrap" :style="`background: #e8e8e8 url('${product.images[0].url}') center center / cover no-repeat;`"></div>
                          <!-- <div style="line-height: 32px;">{{ product.name }}</div> -->
                        </Dropdown>
                      </div>
                      <div class="ml-3 overflow-hidden">
                        {{ product.name }}
                        <div class="text-sm">{{ product.variants[0].price }}€</div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-4">Produkte</div>

        <div class="mt-4">
          <template v-for="product in products">
            <div class="flex pl-2 py-2 border-b border-gray hover:bg-gray-active cursor-pointer">
              <div class="w-full flex overflow-hidden">
                <div class="relative" style="padding-top: 1px;">
                  <div class="absolute left-0 top-0 rounded-full w-2 h-2 bg-danger z-10" v-if="product"></div>
                  <Dropdown class="flex" style="padding: 0;">
                    <div class="bg-gray rounded-sfull mr-2 h-12 w-10 overflow-auto whitespace-no-wrap" :style="`background: #e8e8e8 url('${product.images[0].url}') center center / cover no-repeat;`"></div>
                    <!-- <div style="line-height: 32px;">{{ product.name }}</div> -->
                  </Dropdown>
                </div>
                <div class="ml-3 overflow-hidden">
                  {{ product.name }}
                  <div class="text-sm">{{ product.variants[0].price }}€</div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="w-1/3">

      </div>
    </div>
  </template>
</AdminPage>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AdminProducts',
  created() {
    this.fetchProducts()
  },
  computed: {
    ...mapGetters({
      products: 'adminProduct/getProducts'
    })
  },
  methods: {
    ...mapActions({
      fetchProducts: 'adminProduct/fetchProducts'
    }),
  },
};
</script>
