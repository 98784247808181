const store = {
  namespaced: true,
  state: {
    page: null
  },
  getters: {

  },
  mutations: {
    setPage: (state, payload) => {
      state.page = payload
    }
  },
  actions: {
    setPage: ({
      commit
    }, payload) => {
      return new Promise(function(resolve, reject) {
        commit('setPage', payload)
        resolve()
      })
    }
  }
};

export default store;
