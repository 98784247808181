import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '../store';

import configRoutes from '../config/routes';
import configCheckoutSteps from '../config/checkout-steps';

import Error404 from '../views/Error404';

import views from '../views';

import { PageApi } from 'api-client'

Vue.use(VueRouter);

// guard(): protects routes that needs authentication
const guard = (to, from, next) => {
  if (store.state.auth.authenticated) {
    next();
  } else {
    store
      .dispatch('auth/authenticate', {
        root: true
      })
      .then(() => {
        store.state.auth.authenticated ? next() : next('/auth');
      })
      .catch((err) => {
        next({
          name: 'login',
          query: to.query
        })
      });
  }
}

// loggedIn(): checks on authenticate routes if user is already authenticated
const loggedIn = (to, from, next) => {
  store
    .dispatch('auth/authenticate', {
      root: true
    })
    .then(() => (store.state.auth.authenticated ? next('/mailbox') : next()))
    .catch(err => next());
  return next();
}

const checkoutLoggedIn = (to, from, next) => {
  store
    .dispatch('auth/authenticate', {
      root: true
    })
    .then(() => (store.state.auth.authenticated ? next({
      name: 'checkout_step_data'
    }) : next()))
    .catch(err => next());
}

// auth(): authenticates user before visiting route, this routes do not require authentication
const auth = (to, from, next) => {
  if (!store.state.auth.authenticated && store.state.auth.accessToken) {
    store.dispatch('auth/authenticate', {
      root: true
    });
  }

  next();
}

function signOut(to, from, next) {
  store.dispatch('auth/signOut', {
      root: true
    }).then(() => {
      next('/auth');
    })
    .catch(err => console.log(err));
}

const checkSession = (to, from, next) => {
  if (!store.state.auth.guestToken) {
    store.dispatch('auth/createGuestSession', {
      root: true
    });
  }

  next();
}

// const authRoute = {
//   name: 'auth',
//   component: Auth,
//   meta: {
//     title: 'Auth',
//   },
//   beforeEnter: loggedIn,
// };

const beforeEnterOptions = {
  auth,
  checkoutLoggedIn,
  loggedIn,
  guard
}

const beforeEnter = (option) => {
  return beforeEnterOptions[option]
}

const routes = [
  { path: '/404', name: '404', component: Error404 },
  { path: '*', component: views.DefaultView },
];

[
  ...configRoutes,
  ...configCheckoutSteps.map(configCheckoutStep => configCheckoutStep.route)
].forEach((route, i) => {
  if (route) {
    routes.push({
      name: route.name,
      path: route.path,
      component: views[route.view],
      beforeEnter: beforeEnter(route.beforeEnter),
      meta: route.meta
    });
  }
});


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (window.shopConfig.Features.PAGE_REDIRECTS) {
    const configured_redirect = window.shopConfig.Features.PAGE_REDIRECTS.data.find(redirect => redirect.from === to.path)

    if (configured_redirect) {
      return next(configured_redirect.to)
    }
  }
  /**
   * Summary: Fetch custom page
   *
   * Description: If page has no name, meaning is not in the routes THEN fetch
   */
  if (!to.name) {
    PageApi.fetchPage({route_path:to.path}).then(res => {
      store.dispatch('page/setPage', res.data, {
        root: true
      }
    )

      next()
    }).catch(err => {
      return next('/404');
    })
  }

  next()
})

router.afterEach((to, from) => {
  let TITLE_PREFIX = null

  if (window.shopConfig && window.shopConfig.Features.CUSTOM_PAGE_TITEL) {
    TITLE_PREFIX = window.shopConfig.Features.CUSTOM_PAGE_TITEL.data.title
  }

  Vue.nextTick(() => {
    document.title = TITLE_PREFIX + ' ' + (to.meta.title ? to.meta.title : '')
  })
})

export default router
