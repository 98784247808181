import {
  BasketApi
} from 'api-client';

const basketModel = {
  items: []
}

const store = {
  namespaced: true,
  state: {
    fetchingBasket: false,
    basketId: localStorage.getItem('basketId') || null,
    basket: {
      items: []
    },
  },
  getters: {
    getBasketId: (state) => {
      return state.basketId
    },
    getBasket: (state) => {
      return state.basket
    },
  },
  mutations: {
    deleteSession: (state) => {
      localStorage.removeItem('basketId')
      state.basketId = null
      state.basket = { ...basketModel }
    },
    setBasketSession: (state, basketId) => {
      if (basketId) {
        localStorage.setItem('basketId', basketId)
        state.basketId = basketId
      }
    },
    updateBasket: (state, payload) => {
      if (payload) {
        state.basket = payload
      }
    },
    removeItem: (state, itemId) => {
      const i = state.basket.items.map(item => item.id === itemId)

      state.basket.items.splice(i, 1)
    }
  },
  actions: {
    fetchBasket: ({
      state,
      commit
    }) => {
      return new Promise(function(resolve, reject) {
        if (state.fetchingBasket) {
          reject({
            basket: 'pending_request'
          })
          return
        }
        if (!state.basketId) {
          reject({
            basket: 'no_basket_session'
          })
          return
        }
        state.fetchingBasket = true
        BasketApi.fetchBasket(state.basketId)
          .then((response) => {
            commit('updateBasket', response.data)
            state.fetchingBasket = false
            resolve(response.data)
          })
          .catch((error) => {
            if (error.response.status === 404) {
              commit('deleteSession')
            }

            state.fetchingBasket = false
            reject(error)
          });
      })
    },
    addItem: ({
      state,
      commit
    }, payload) => {
      return new Promise(function(resolve, reject) {
        BasketApi.addItem({
            ...payload,
            basketId: state.basketId
          })
          .then((response) => {
            commit('setBasketSession', response.data.id)
            commit('updateBasket', response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          });
      });
    },
    updateItem: ({
      state,
      commit
    }, payload) => {
      return new Promise(function(resolve, reject) {
        BasketApi.updateItem(payload)
          .then((response) => {
            commit('updateBasket', response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          });
      })
    },
    removeItem: ({
      state,
      commit
    }, payload) => {
      commit('removeItem', payload.itemId)
      return new Promise(function(resolve, reject) {
        BasketApi.removeItem(payload)
          .then((response) => {
            commit('updateBasket', response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          });
      })
    },
    deleteSession: ({
      commit
    }) => {
      return new Promise(function(resolve, reject) {
        commit('deleteSession')
        resolve()
      })
    }
  }
};

export default store;
