<template>
  <div class="fixed left-0 top-0 z-10 p-1 text-xs">
    <div class="w-8 h-8 bg-white shadow-md rounded-md overflow-hidden" :class="{'w-64 h-full p-1':active}" @click="active=true">
      The Tool

      <template v-for="tool in tools">
        <div class="flex">
          <div class="w-1/3">
            {{ tool.name }}
          </div>
          <div class="w-2/3 flex justify-end">
            <component v-model="model[tool.key]" :is="tool.component"></component>
          </div>
        </div>
      </template>
      <div class="flex justify-between" v-for="tool in tools">
        <div>{{ tool.key.split("_").pop() }}: <input class="ml-2 w-16" v-model="model[tool.key]">{{ tool.unit }}</div>
        <div>
          <div class="h-4 w-4" v-if="tool.type === 'color'" :style="`background: ${model[tool.key]};`"></div>
          <div v-if="tool.type === 'size'">-</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ToolColorPicker from './components/color-picker'
import ToolRangeSlider from './components/range-slider'

let root = document.documentElement

export default {
  name: 'Toolbox',
  components: {
    ToolColorPicker,
    ToolRangeSlider
  },
  watch: {
    'model': {
      handler() {
        Object.keys(this.model).forEach((key, i) => {
          const tool = this.tools.find(tool => tool.key === key)
          if (tool.unit) {
            root.style.setProperty(key, this.model[key] + tool.unit)
          } else {
            root.style.setProperty(key, this.model[key])
          }
        });
      },
      deep: true
    }
  },
  data() {
    return {
      active: false,
      tools: [
        {
          component: 'ToolColorPicker',
          type: 'color',
          key: '--button-primary__background',
          name: 'Background'
        },
        {
          component: 'ToolColorPicker',
          type: 'color',
          key: '--button-primary__border-color',
          name: 'Border color'
        },
        {
          component: 'ToolRangeSlider',
          type: 'size',
          key: '--button-primary__border-width',
          name: 'Border width',
          unit: 'px'
        },
        {
          component: 'ToolRangeSlider',
          type: 'size',
          key: '--button-primary__border-radius',
          name: 'Border radius',
          unit: 'px'
        }
      ],
      model: {}
    }
  }
}
</script>

<style lang="css" scoped>
</style>
