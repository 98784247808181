<template>
<form class="form-generator" :class="formSchema.classes" v-if="formSchema">
  <template v-if="formSchema.rows">
    <div :class="row.classes" v-for="row in formSchema.rows">
      <template v-if="row.cols">
        <div :class="col.classes" :style="col.styles" v-for="col in row.cols">
          <component v-if="col.component && col.component.key === 'OInput'" v-model="model[col.model]" class="form__field" :class="`field-${col.model}`" :is="col.component.key" v-bind="col.component.attributes" :error="formErrors[col.model]" :disabled="disabled">
            <template v-if="col.component.slotContent">{{ item.component.slotContent }}</template>
          </component>
          <component v-else-if="col.component" :is="col.component.key" :value="model[col.model]" v-bind="col.component.attributes"></component>
        </div>
      </template>
    </div>
  </template>
  <div v-if="formError" class="text-red-600 text-xs" style="max-height: 18px;overflow: hidden;">
    {{ formError }}
  </div>
</form>
</template>

<script>
import _ from 'lodash'
import formSchemas from '../../config/forms'

export default {
  name: 'FormGenerator',
  model: {
    prop: 'value',
    event: 'update',
  },
  props: {
    schema: String,
    value: Object,
    errors: [Object, String],
    disabled: Boolean
  },
  // created() {
  //   if (this.value) {
  //     this.model = this.value
  //   }
  // },
  watch: {
    'value': {
      handler() {
        this.model = this.value
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    formSchema() {
      return formSchemas[this.schema]
    },
    formError() {
      if (_.isString(this.errors)) {
        return this.$t(this.errors)
      }

      return null
    },
    formErrors() {
      if (this.errors) {
        return this.errors
      }
      return {}
    }
  },
  data() {
    return {
      model: {}
    }
  }
}
</script>
