<template>
  <div style="border-radius: 2px;">
    <Dropdown class="action-menu__dropdown" size="l" :items="menu" dropdown-key="item.key" position="left" @click="onClickDropdownItem">
      <!-- <span class="mr-2" v-if="$store.state.auth.authenticated">{{ $store.state.user.user.firstname }}</span> -->
      <Icon icon="burger-menu" :height="24"/>
    </Dropdown>
  </div>
</template>

<script>
import {
  TimelineLite,
} from 'gsap'
import {
  mapActions
} from 'vuex'

import components from '../../config/components'

export default {
  name: 'NavItemMobileMenu',
  computed: {
    userDropdown() {
      return components.NAVIGATION_ITEM_USER.items
    },
    visitorDropdown() {
      return [{
        key: 'login',
        name: 'Anmelden',
        route: 'login'
      }]
    },
    menu() {
      return this.$store.state.auth.authenticated ? this.userDropdown : this.visitorDropdown
    },
  },
  watch: {
    '$store.state.auth.authenticated': {
      handler() {
        if (this.$store.state.auth.authenticated) {
          const {
            loggedInIndicator,
          } = this.$refs
          const timeline = new TimelineLite()

          this.loggedInIndicatorAnimation = timeline.to(loggedInIndicator, 0.3, {
            scale: 1,
            opacity: 1
          })
        } else {
          if (this.loggedInIndicatorAnimation) {
            this.loggedInIndicatorAnimation.reverse()
          }
        }
      },
      immidiate: true
    }
  },
  methods: {
    ...mapActions({
      signOut: 'auth/signOut'
    }),
    onClickDropdownItem(key) {
      if (key === 'sign_out') {
        this.signOut().then(() => {
          this.$router.push({
            name: 'shop'
          })
        })
      }
    }
  },
  data() {
    return {
      loggedInIndicatorAnimation: null
    }
  }
}
</script>
