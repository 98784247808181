<template>
<div id="app">
  <Page>
    <notifications group="status" />
    <notifications group="error" />
    <vue-page-transition name="fade-in-up">
      <router-view />
    </vue-page-transition>
  </Page>
  <!-- <Toolbox></Toolbox> -->
</div>
</template>

<script>
import Toolbox from './components/toolbox/toolbox'
import {
  mapGetters
} from 'vuex';
import {
  ModalTechnicalErrorMessage
} from '@/02-components';

let root = document.documentElement;

export default {
  name: 'App',
  components: {
    Toolbox
  }
}
</script>
