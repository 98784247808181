import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
import basket from './modules/basket';
import payment from './modules/checkout/payment';
import order from './modules/checkout/order';
import productCategory from './modules/product/category';
import product from './modules/product/product';
import page from './modules/page';
import user from './modules/user';

import adminProduct from './modules/admin/product';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    basket,
    payment,
    order,
    productCategory,
    product,
    adminProduct,
    page,
    user
  },
});
