import BasketView from './Basket.vue';
import Checkout from './Checkout/Checkout.vue';
import DefaultView from './Default.vue';
import Product from './Shop/Product/Product.vue';
import Shop from './Shop/Shop.vue';
import ShopCategory from './Shop/ShopCategory.vue';
import CustomerAccount from './Customer/CustomerAccount.vue';
import CustomerSettings from './Customer/CustomerSettings.vue';
import CustomerOrders from './Customer/CustomerOrders.vue';
import LoginView from './Login.vue';
import RegisterView from './Register.vue';

import AdminProducts from './Admin/Products.vue';
import AdminShopConfigurator from './Admin/ShopConfigurator.vue';
import AdminShopDesigner from './Admin/Builder.vue';

export default {
  BasketView,
  Checkout,
  DefaultView,
  Product,
  Shop,
  ShopCategory,
  CustomerAccount,
  CustomerOrders,
  CustomerSettings,
  LoginView,
  RegisterView,

  AdminProducts,
  AdminShopConfigurator,
  AdminShopDesigner
}
