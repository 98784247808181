<template>
  <div class="container m-auto mt-8">
    <div class="flex">
      <div class="text-2xl mr-4">{{ title }}</div>
      <ActionMenu :groups="menu" @click="onClickMenuItem"></ActionMenu>
    </div>

    <div class="flex mt-2">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SmartPage',
  props: {
    title: {
      type: String,
      default: 'Smart Title',
    },
    editView: {
      type: Boolean
    },
    menu: {
      type: Array,
      default: () => []
    },
    formSchema: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    onClickMenuItem(key) {
      this.$emit('click-menu-item', key);
    }
  },
  data() {
    return {

    }
  }
};
</script>

<style lang="scss" scoped>

</style>
