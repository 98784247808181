<template>
<Page>
  <div class="container mx-auto md:mt-32">
    <div v-html="$store.state.page.page.content"></div>

    <ComponentsBuilder v-if="$store.state.page.page.json" :json="$store.state.page.page.json"></ComponentsBuilder>
  </div>
</Page>
</template>

<script>
export default {
  name: 'Error404'
};
</script>
