import {
  AdminProductApi
} from 'api-client';

const store = {
  namespaced: true,
  state: {
    products: null
  },
  getters: {
    getProducts: (state) => {
      return state.products
    }
  },
  mutations: {
    updateProducts: (state, payload) => {
      state.products = payload
    }
  },
  actions: {
    fetchProducts: ({commit, dispatch, state}, payload) => new Promise((resolve, reject) => {
      AdminProductApi.fetchProducts(payload)
        .then((response) => {
          commit('updateProducts', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        });
    }),
  },
};

export default store
