<template>
  <div class="o-text">
    <label v-if="label">{{ label }}</label>
    <textarea
      :class="classes"
      v-bind="$attrs"
      :value="value"
      @input="onChange"
      @focus="onFocus"
      @blur="onBlur"
      @keydown.enter="onKeyDownEnter"
    ></textarea>
  </div>
</template>

<script>
export default {
  name: 'OTextarea',
  inheritAttrs: false,
  model: {
    prop: 'value',
    event: 'update',
  },
  props: {
    value: String,
    label: {
      type: String,
      default: '',
    },
    maxRows: {
      type: Number,
      default: 20,
    },
  },
  computed: {
    classes() {
      return [
        'textarea',
        this.value && 'has-content',
      ];
    },
  },
  methods: {
    onChange(event) {
      const { value } = event.currentTarget;
      this.$emit('update', value);
      this.$emit('change', event, value);
    },
    onBlur(event) {
      const { value } = event.currentTarget;
      this.$emit('blur', event, value);
    },
    onFocus(event) {
      const { value } = event.currentTarget;
      this.$emit('focus', event, value);
    },
    addRow(el) {
      const rows = el.rows + 1;
      if (this.maxRows >= rows) {
        el.rows += 1;
      }
    },
    onKeyDownEnter(event) {
      console.log(event);
      this.addRow(event.target);
    },
  },
};
</script>

<style src="./textarea.scss" lang="scss"></style>
