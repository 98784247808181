import client from 'client'

export default {
  fetchPage(payload) {
    return client.get('/v1/page', {
      params: {
        path: payload.route_path
      }
    })
  }
}
