<template>
  <div :class="classes">
    <Icon v-if="icon" :icon="icon"/>
    {{ title }}
    {{ text }}
    <slot />
  </div>
</template>

<script>
const HEADLINE_PREFIX = 'headline font-semibold';
const HEADLINE_SIZES = {
  S: 'text-xl',
  M: 'text-2xl',
  L: 'text-4xl',
  XL: 'text-6xl',
};

export default {
  name: 'Headline',
  props: {
    size: {
      default: 'M'
    },
    title: String,
    text: String,
    icon: String,
    sticky: Boolean,
  },
  computed: {
    classes() {
      return [
        `${HEADLINE_PREFIX} ${HEADLINE_SIZES[this.size]}`,
        this.sticky && 'is-sticky',
      ];
    },
  },
};
</script>

<style>
.headline {

}
.headline.is-sticky {
  position: sticky;
  top: 0;
  z-index: 1;
  @apply bg-white;
}
</style>
