<template>
  <label class="o-label font-medium text-sm">
    {{ text }}
    <slot/>
  </label>
</template>

<script>
export default {
  name: 'OLabel',
  props: {
    text: String
  }
};
</script>
