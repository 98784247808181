<template>
  <div class="modal" :class="classes">
    <div class="modal__body" v-click-outside="onClose" ref="modal_body">
      <div class="modal__header"></div>

      <slot/>
    </div>
    <div class="modal__overlay" ref="modal__overlay"/>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import gsap from 'gsap'

export default {
  name: 'Modal',
  props: {
    classes: {
      type: String,
      default: ''
    }
  },
  mounted() {
    gsap.to(this.$refs.modal_body, {duration: 0.3, scale: 1, transformOrigin: 'center'})
    gsap.to(this.$refs.modal_body, {duration: 0.6, opacity: 1})

    gsap.to(this.$refs.modal__overlay, {duration: 0.6, opacity: 1})
  },
  methods: {
    onClose() {
      this.$emit('close')
    }
  },
  directives: {
    ClickOutside,
  },
}
</script>

<style>
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 40;
    height: 100%;
    width: 100%;
    padding: var(--modal__padding);
    overflow: auto;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(9px);
  }

  .modal__body {
    width: var(--modal-body__width);
    margin: auto;
    background: #fff;
    padding: var(--modal-body__padding);
    box-shadow: var(--modal-body__box-shadow);
    position: relative;
    z-index: 20;
    border: 1px solid #eeeeee;
    border-radius: 2px;

    transform: scale(0.95);
    opacity: 0;
  }

  .modal__overlay {
    opacity: 0;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    /* background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(9px); */
  }
</style>
