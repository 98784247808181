<template>
  <div class="payment-option px-4 pb-4">
    <form id="payment-form">
      <div id="card-element" class="input px-4 py-3">

      </div>

      <div id="card-errors" role="alert"></div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PaymentOptionStripe',
  props: {
    data: Object
  },
  created() {
    const client_token = this.data.client_token

    this.insertSdk().then(() => {
      this.sdk = Stripe(
        'pk_test_7oDCP6rUfleQAVQekeK3ocYL00XKfcCVpv',
        {
          locale: 'de'
        }
      );
      var elements = this.sdk.elements();

      var style = {
        base: {
          color: "#32325d",
        }
      };

      this.card = elements.create("card", {
        style: style
      });
      this.card.mount("#card-element");
    })
  },
  computed: {
    ...mapGetters({
      orderBillingAddress: 'order/getOrderBillingAddress'
    }),
  },
  methods: {
    insertSdk() {
      return new Promise((resolve, reject) => {
        if (this.isSdkLoaded) {
          return
        }
        const scripts = document.getElementsByTagName('script')[0]
        const scriptElement = document.createElement('script')
        scriptElement.src = 'https://js.stripe.com/v3/'
        scriptElement.id = 'stripe-sdk'
        scripts.parentNode.insertBefore(scriptElement, scripts)
        scriptElement.onload = () => {
          resolve()
          this.isSdkLoaded = true
        }
      })
    },
    confirm() {
      const vm = this
      return new Promise((resolve, reject) => {
        this.sdk.confirmCardPayment(this.data.secret, {
          payment_method: {
            card: this.card,
            billing_details: {
              name: this.orderBillingAddress.firstname + ' ' + this.orderBillingAddress.lastname
            }
          }
        }).then((result) => {
          if (result.error) {
            reject({ key: 'payment_stripe_' + result.error.code })
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              resolve()
            }
          }
          vm.loading = false
        })
      })
    }
  },
  data() {
    return {
      isSdkLoaded: false,
      sdk: null,
      card: null
    }
  }
}
</script>
