import {
  ProductCategoryApi
} from 'api-client'

import cacheService from '../../../services/cache'

const store = {
  namespaced: true,
  state: {
    categories: [],
    category: {}
  },
  getters: {
    getCategories: (state) => {
      return state.categories
    },
    getCategory: (state) => {
      return state.category
    },
    getItems: (state) => {
      return state.category.items
    }
  },
  mutations: {
    updateCategories: (state, payload) => {
      state.categories = payload
    },
    updateCategory: (state, payload) => {
      state.category = payload
    },
  },
  actions: {
    fetchCategories: ({commit, dispatch, state}, payload) => new Promise((resolve, reject) => {
      const categories = cacheService.getCache('shop_categories')

      if (!categories) {
        ProductCategoryApi.fetchCategories()
          .then((response) => {
            commit('updateCategories', response.data)
            // cacheService.setCache('shop_categories', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          });
        return
      }

      commit('updateCategories', categories)
    })
  },
};

export default store
