<template>
  <div class="footer-col-wrapper">
    <Headline v-if="title" class="footer-col__title" :title="title" :size="title_size"/>

    <ul class="footer-col" :class="classes">
      <li v-for="item in items" :class="item.classes">
        <!-- <component :is="item.component ? item.component : 'span'"> -->
          {{ item.text }}
          <span v-if="item.html" v-html="item.html"/>
          <img v-if="item.image" :src="item.image.url" :style="`height:${(item.image.size ? item.image.size : 32)}px;`">
          <component v-if="item.component" :is="item.component.key" v-bind="item.component.attributes">{{ item.text }}</component>
        <!-- </component> -->
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'FooterCol',
  props: {
    title: String,
    title_size: {
      type: String,
      default: 'S'
    },
    items: Array,
    classes: String
  },
};
</script>

<style>
  .footer-col__title {
    margin: var(--footer-col-title__margin);
  }
</style>
