<template>
  <div class="o-time-picker" v-click-outside="onHide">
    <!-- <label class="time-picker__label" v-if="show">{{ label }}</label> -->
    <OInput
      class="input-sheet"
      v-model="time.decimal"
      @input="syncTime"
      @click.native="onShow()"
      :schema-key="schemaKey"
      size="s"
      :disabled="disabled"/>
    <slot name="time" @click.native="onShow()"/>

    <div class="w-48 rounded shadow bg-white absolute z-10 p-4" v-if="show">
      <div class="text-sm font-bold">{{ label }}</div>
      <div class="flex mt-2">
        <OInput
          v-model="time.hour"
          @input="syncDecimal"
          :invalid="$v.time.hour.$invalid"
          schema-key="hours"
          size="s"/>
        <span class="mx-1">:</span>
        <OInput
          v-model="time.minute"
          @input="syncDecimal"
          :invalid="$v.time.minute.$invalid"
          schema-key="minutes"
          size="s"/>
        <!-- {{tweenedNumber.toFixed(0)}} -->
      </div>
      <!-- <div class="text-sm text-danger mt-2" v-if="$v.$error">
        Please check the time
      </div> -->
      <div class="flex justify-between">
        <Button class="mt-4" @click="onUpdate()" size="xs">done</Button>
        <!-- <Button class="mt-4" @click="onHide()" color="grayText" size="xs">close</Button> -->
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

import {
  required, minLength, maxLength, between,
} from 'vuelidate/lib/validators';

import moment from 'moment';
import gsap from 'gsap';

const format = (value, rule) => {
  if ('2 decimal places') {
    return (Math.round(value * 100) / 100).toFixed(2);
  }

  return false;
};

const timeConvert = (value, conditions) => {
  const { from, to } = conditions;

  if (from === 'minute' && to === 'decimal') {
    return value * (100 / 60);
  }

  if (from === 'hour' && to === 'decimal') {
    return value * (10 / 24);
  }

  if (from === 'decimal' && to === 'hour') {
    return parseFloat(value.split('.')[0]);
  }

  if (from === 'decimal' && to === 'minute') {
    return parseFloat(value).toFixed(2).split('.')[1] * (60 / 100);
  }

  return false;
};

export default {
  name: 'OTimePicker',
  model: {
    prop: 'value',
    event: 'update',
  },
  props: {
    value: {
      type: String,
    },
    schemaKey: {
      type: String,
    },
    label: {
      type: String,
      default: '',
    },
    disabled: Boolean,
  },
  created() {
    if (this.value) {
      this.time.initial = this.value;
      this.time.new = this.value;

      this.syncDecimal();
      this.syncTime();
    }
  },
  watch: {
    value: {
      handler(oldVal, newVal) {
        if (oldVal !== newVal) {
          this.time.initial = this.value;
          this.time.new = this.value;
        }
      },
      deep: true,
    },
    // 'time.decimal': {
    //   handler() {
    //     this.syncTime();
    //     // this.time.minute = moment(this.time.new).format('mm');
    //   },
    //   deep: true
    // },
    // 'time.hour': {
    //   handler() {
    //     this.syncDecimal();
    //   },
    //   deep: true
    // }
  },
  methods: {
    syncDecimal() {
      this.updateTime();
      const hour = moment(this.time.new).utc().format('k');
      let minute = moment(this.time.new).utc().format('mm');
      minute = timeConvert(minute, { from: 'minute', to: 'decimal' }).toFixed(0);

      this.time.decimal = `${hour}.${minute}`;
    },
    syncTime(event) {
      const hour = timeConvert(this.time.decimal, { from: 'decimal', to: 'hour' });

      if (hour === 24) {
        this.time.hour = 0;
      } else {
        this.time.hour = timeConvert(this.time.decimal, { from: 'decimal', to: 'hour' });
      }
      const minute = timeConvert(this.time.decimal, { from: 'decimal', to: 'minute' }).toFixed(0);
      this.time.minute = minute;

      this.update();
      // gsap.to(this.$data, { duration: 0.5, tweenedNumber: minute });
    },
    onToggle() {
      if (!this.disabled) {
        this.show = !this.show;
      }
    },
    onShow() {
      if (!this.disabled) {
        this.show = true;
      }
    },
    onHide() {
      this.show = false;
    },
    updateTime() {
      this.time.new = moment(this.time.new).utc().set({
        hour: this.time.hour,
        minute: this.time.minute,
      }).format();
    },
    update() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.updateTime();
        this.$emit('update', this.time.new);
        this.show = false;
      }
    },
    onUpdate() {
      this.update();
      this.$emit('submit', this.time.new);
    },
  },
  data() {
    return {
      time: {
        initial: null,
        new: null,
        decimal: null,
        hour: null,
        minute: null,
      },
      tweenedNumber: 0,
      show: false,
    };
  },
  validations: {
    time: {
      hour: {
        minLength: minLength(0),
        maxLength: maxLength(2),
        between: between(0, 24),
      },
      minute: {
        minLength: minLength(0),
        maxLength: maxLength(2),
        between: between(0, 60),
      },
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style lang="scss" scoped>
.o-time-picker {
  position: relative;
  .time-picker {
    &__label {
      position: absolute;
      top: -21px;
      left: 0;
    }
  }
}
</style>
