import {
  UserApi
} from 'api-client';

const store = {
  namespaced: true,
  state: {
    user: null,
    orders: []
  },
  getters: {
    getUser: (state) => {
      return state.user
    },
    getOrders: (state) => {
      return state.orders.reverse()
    },
  },
  mutations: {
    updateUser: (state, payload) => {
      state.user = payload
    },
    clearUser: (state) => {
      state.user = null
    },
    updateOrders: (state, payload) => {
      state.orders = payload
    },
  },
  actions: {
    fetchOrders: ({commit, dispatch, state}, payload) => new Promise((resolve, reject) => {
      UserApi.fetchOrders(payload)
        .then((response) => {
          commit('updateOrders', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error.response)
        });
    }),
    resetPassword: ({commit, dispatch, state}, payload) => new Promise((resolve, reject) => {
      UserApi.resetPassword(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error.response)
        });
    }),
  }
}

export default store
