import {
  ProductApi
} from 'api-client'

import cacheService from '../../../services/cache'

const store = {
  namespaced: true,
  state: {
    category: null
  },
  getters: {
    getCategory: (state) => {
      return state.category
    }
  },
  mutations: {
    updateCategory: (state, payload) => {
      state.category = payload
    }
  },
  actions: {
    fetchProductByKey: ({commit, dispatch, state}, payload) => new Promise((resolve, reject) => {
      ProductApi.fetchProductByKey(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    }),
    fetchCategoryByKey: ({commit, dispatch, state}, category_key) => new Promise((resolve, reject) => {
      const category = cacheService.getCache('shop_category_' + category_key)

      if (!category) {
        ProductApi.fetchCategoryByKey(category_key)
          .then((response) => {
            commit('updateCategory', response.data)
            // cacheService.setCache('shop_category_' + category_key, response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }

      commit('updateCategory', category)
    }),
  },
}

export default store
