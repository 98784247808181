<template>
  <div class="basket">
    <BasketItemList/>
    <div class="text-right">Gesamtsumme: {{ basket._total }}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Basket',
  computed: {
    ...mapGetters({
      basket: 'basket/getBasket'
    }),
    basketItems() {
      return this.basket.items.map(item => ({
        id: item.id,
        title: item.variant ? item.variant.name : '',
        image: item.product.images[0].url,
        additional_lines: [
          {
            text: 'Anzahl ' + item.quantity,
            classes: 'text-sm'
          },
          {
            text: item.total_amount,
            classes: 'text-right'
          }
        ]
      }))
    }
  }
}
</script>

<style lang="scss">
  .basket__item-list {
      margin-bottom: 8px;
    .list__item {
      padding: 12px 8px 0px 0px;
    }
  }
</style>
