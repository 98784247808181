<template>
<div class="container p-4 md:p-0 mx-auto md:flex md:mt-16">
  <div class="md:w-2/3">
    <div v-if="false">
      <div class="cursor-pointer" v-if="(!$store.state.auth.authenticated && currentStep === 'data') || currentStep !== 'data'" @click="$router.go(-1)">
        Zurück
      </div>
      <template v-else>

      </template>
    </div>
    <!-- Mode MultiStep -->
    <template v-if="checkoutFlowMode === 'multi-step'">
      <transition-group name="fade" tag="div">
        <template v-for="step in checkoutSteps" v-if="step.name === currentStep">
          <component :disabled="fetching_order" :fetching_order="fetching_order" :flow-config="checkoutFlowMode" :is="step.view" @done="onStepDone(step)" :key="step.view"></component>
        </template>
      </transition-group>
    </template>

    <!-- Mode OneStep -->
    <template v-if="checkoutFlowMode === 'one-step'">
      <template v-for="step in checkoutSteps">
        <component :flow-config="checkoutFlowMode" :is="step.view"></component>
      </template>
    </template>
  </div>
  <div class="mt-32 md:w-1/3 md:mt-0">
    <template v-if="!step.features.hideBasket">
      <Basket></Basket>
    </template>
  </div>
</div>
</template>

<script>
import {
  mapActions,
  mapGetters
} from 'vuex';
import configCheckoutSteps from '../../config/checkout-steps'
import steps from './steps';

export default {
  name: 'Checkout',
  components: {
    ...steps,
  },
  created() {
    if (!this.$store.state.basket.basket) {
      this.fetchBasket().then(() => {
        /**
         * Summary: Handle empty basket in order flow
         *
         * Description: Redirect user to basket empty page
         */
        this.handleEmptyBasket()
        /**
         * Summary: Fetch order if basket in session
         */
        this._fetchOrder()
      }).catch((err) => {
        if (err.basket === 'pending_request') {
          return
        }
        this.handleEmptyBasket()
      })
    } else {
      this._fetchOrder()
    }

    this.setStepByRoute()
  },
 beforeRouteLeave (to, from, next) {
   /**
   * Summary: Handle authenticated user redirection
   *
   * Description: If user authenticated and previous step authentication THEN ask if redirect to shop
   */
   if (to.name === 'checkout' && from.name === 'checkout_step_data' && this.$store.state.auth.authenticated) {
     if (window.confirm('Zurück zum Shop?')) {
        return this.$router.push({ name: 'shop' })
      }
      return this.$router.push({ name: 'checkout_step_data' })
   }

   next()
 },
  computed: {
    ...mapGetters({
      basket: 'basket/getBasket'
    }),
    checkoutSteps() {
      return configCheckoutSteps
    },
    step() {
      return this.checkoutSteps.find(step => step.name === this.currentStep)
    },
    checkoutFlowMode() {
      if (window.shopConfig.Features.CHECKOUT_FLOW_MODE) {
        return window.shopConfig.Features.CHECKOUT_FLOW_MODE.data.mode
      } else {
        return 'multi-step'
      }
    }
  },
  watch: {
    $route(to, from) {
      this.setStepByRoute()
    },
    'basket': {
      handler() {
        this.handleEmptyBasket()
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      fetchBasket: 'basket/fetchBasket',
      fetchOrder: 'order/fetchOrder'
    }),
    getNextStep(nextStep) {
      return this.checkoutSteps.find(step => step.name === nextStep)
    },
    onStepDone(step) {
      const nextStep = this.getNextStep(step.nextStep)

      if (step.route) {
        this.$router.push({
          name: nextStep.route.name
        })

        this.scrollToTop()
      }
    },
    setStep(step) {
      this.currentStep = step
    },
    setStepByRoute() {
      const step = this.checkoutSteps.find(step => step.route.name === this.$route.name)

      this.setStep(step.name)
    },
    handleEmptyBasket() {
      if (!this.basket.items.length) {
        this.$router.push({
          name: 'basket'
        })
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    _fetchOrder() {
      this.fetching_order = true
      this.fetchOrder().then(() => {
        this.fetching_order = false
      }).catch(() => {
        if (this.$route.name === 'checkout_step_payment') {
          this.$router.push({ name: 'checkout' })
        }
        this.fetching_order = false
      })
    }
  },
  data() {
    return {
      currentStep: 'auth',
      fetching_order: true,
    }
  }
};
</script>
