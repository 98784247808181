<template>
<div class="container mx-auto md:mt-32">
  <div v-if="basketId && basket && basket.items.length">
    <Headline>Warenkorb</Headline>
    <Basket></Basket>
    <Button route="checkout" class="mt-8" size="l">Zum Checkout</Button>
  </div>
  <template v-else>
    <div class="mt-4 text-center">
      <div class="text-2xl">Dein Warenkorb ist leer</div>

      <router-link :to="{ name: 'shop' }">
        <Button class="mt-8" size="l">Jetzt zum Shop</Button>
      </router-link>
    </div>
  </template>
</div>
</template>

<script>
import {
  mapActions,
  mapGetters
} from 'vuex';

export default {
  name: 'BasketView',
  created() {
    if (this.basketId) {
      this.fetchBasket()
    }
  },
  computed: {
    ...mapGetters({
      basketId: 'basket/getBasketId',
      basket: 'basket/getBasket'
    })
  },
  methods: {
    ...mapActions({
      fetchBasket: 'basket/fetchBasket'
    }),
  },
};
</script>
