<template>
  <component
    :is="getElementByType"
    v-bind="attrs"
    :class="classes"
    @click="onClick"
    edit="button"
  >
    <div class="absolute text-center left-0 w-full flex justify-center">
      <Loading  v-if="loading" />
    </div>
    <div :class="{'opacity-0':loading}">
      <slot />
    </div>
    {{ text }}
  </component>
</template>
<script>
import Loading from './loading.vue'

const BUTTON_TYPES = new Map(
  Object.entries({
    block: 'div',
    button: 'button',
    submit: 'button',
    link: 'a',
  }),
);

const TEXT_ALIGN = {
  left: 'text-left',
  center: 'text-center',
  right: 'text-right',
};


export default {
  name: 'Button',
  docs: {
    slot: 'Button',
  },
  components: { Loading },
  props: {
    type: {
      type: String,
      default: 'block',
      validator: value => BUTTON_TYPES.has(value),
    },
    size: {
      type: String,
      default: 'm'
    },
    color: {
      type: String,
      default: 'primary'
    },
    textAlign: {
      type: String,
      default: 'center',
      validator: value => Boolean(TEXT_ALIGN[value]),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fullsize: {
      type: Boolean,
      default: false,
    },
    loading: Boolean,
    route: {
      type: String,
    },
    text: String,
    // target: String
  },
  computed: {
    getElementByType() {
      const getEl = BUTTON_TYPES.get(this.type);
      return getEl || this.type.default;
    },
    classes() {
      return [
        'button',
        'button--' + this.size,
        TEXT_ALIGN[this.textAlign],
        this.disabled ? 'is-disabled' : '',
        this.fullsize ? 'w-full' : '',
        'button--' + this.color
      ];
    },
    attrs() {
      if (this.type === 'submit') {
        return {
          type: 'submit',
        };
      }
      return {
        type: '',
      };
    },
  },
  methods: {
    onClick(event) {
      if (this.route) {
        this.$router.push({ name: this.route });
        return;
      }
      this.$emit('click', event);
    }
  }
};
</script>

<style>
.button {
  font-family: var(--button__font-family);
  border-radius: var(--button--primary__border-radius);
  border: 1px solid;
  border-width: var(--button--primary__border-width);
  font-weight: var(--button__font-weight);
  cursor: pointer;
  display: inline-block;
  position: relative;
}

.button--xs {
  padding: var(--button--xs__padding);
}
.button--s {
  padding: var(--button--s__padding);
}
.button--m {
  padding: var(--button--m__padding);
}
.button--l {
  padding: var(--button--l__padding);
}

.button--primary {
  background: var(--button--primary__background);
  border-color: var(--button--primary__border-color);
  color: var(--button--primary__text-color);
}
.button--primary.is-disabled {
  background: var(--button--primary--disabled__background);
  color: var(--button--primary--disabled__text-color);
  border-color: var(--button--primary--disabled__border-color);
  cursor: not-allowed;
}

.button--white {
  background: #fff;
  color: var(--button--white__text-color);
}
</style>
