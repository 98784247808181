<template>
<div class="px-4">
  <div v-if="orders.length" class="md:flex">
    <List :items="order_items" :selected="selected_order" title="Liste" shadow/>

    <Card class="hidden md:block" v-if="order">
      <Order
        :order_id="order.orderId"
        :status="order.status"
        :items="order._items"
        :total="order.totals._total"
        :payment_option="order.payment_option"
        :downloads="order.downloads"
      />
    </Card>

    <Modal class="md:hidden" v-if="order">
      <Order
        :order_id="order.orderId"
        :status="order.status"
        :items="order._items"
        :total="order.totals._total"
        :payment_option="order.payment_option"
        :downloads="order.downloads"
      />
    </Modal>
  </div>

  <template v-else-if="!fetching_orders">
    <Headline>Du hast noch keine Bestellungen</Headline>

    <Button class="mt-8" route="shop">Zum Shop</Button>
  </template>
</div>
</template>

<script>
import {
  mapActions,
  mapGetters
} from 'vuex'
import Order from '../../components/order'

const ORDER_STATUS = {
  cancelled: 'primary',
  completed: 'success',
  processing: 'info'
}

export default {
  name: 'CustomerOrders',
  components: {
    Order
  },
  created() {
    this.fetching_orders = true
    this.fetchOrders().then(() => {
      this.fetching_orders = false
    })
  },
  watch: {
    '$route': {
      handler() {
        if (this.$route.params.orderId) {
          this.selected_order = this.$route.params.orderId
        } else {
          this.selected_order = null
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters({
      orders: 'user/getOrders'
    }),
    order() {
      let order = {...this.orders.find(order => order.id === this.selected_order)}

      if (order && order.items) {
        order.status = {
          text: this.$t(`order_status.${order.status}`),
          type: ORDER_STATUS[order.status]
        }

        order._items = order.items.map(item => ({
          id: order.id,
          title: item.name,
          description: item._total,
          image: item.image_url,
          additional_lines: [
            {
              text: 'Artikelnummer ' + item.variant_id
            },
            {
              // text: 'Datei ' + item.files
            }
          ]
        }))

        return order
      }

      return null
    },
    navigation() {
      return {
        title: {
          text: 'Bestellungen'
        }
      }
    },
    order_items() {
      return this.orders.map(order => ({
        id: order.id,
        title: order.items.length + ' Artikel',
        description: order._orderDate,
        image: order.items[0].image_url,
        status: {
          text: this.$t(`order_status.${order.status}`),
          type: ORDER_STATUS[order.status]
        },
        route: {
          name: 'customer_order',
          params: {
            orderId: order.id
          }
        }
      }))
    }
  },
  methods: {
    ...mapActions({
      fetchOrders: 'user/fetchOrders'
    }),
    onClickOrder(orderId) {
      this.$router.push({ name: 'customer_order', params: { orderId } })
    }
  },
  data() {
    return {
      selected_order: null,
      fetching_orders: false
    }
  }
};
</script>
