import client from 'client'
import store from '../../../store'

export default {
  fetchOrders() {
    return client.get('/v1/orders')
  },
  fetchOrder() {
    return client.get('/v1/order')
  },
  createOrder(payload) {
    return client.post('/v1/order', payload, {
      headers: {
        "basket-id": store.state.basket.basketId
      },
    })
  },
  updateOrderData(payload) {
    return client.put('/v1/order/data', payload)
  },
  setPaymentMethod(payload) {
    return client.post('/v1/order/payment', payload)
  },
  confirmOrder(payload) {
    return client.post('/v1/order/confirm', payload)
  },
  fetchOrderConfirmationStatus() {
    return client.get('/v1/order/confirm')
  }
};
