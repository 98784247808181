<template>
<div :class="`page page-${$route.name} ${classes}`">
  <div class="page__body">
    <ComponentsBuilder :schema="customNavigation"></ComponentsBuilder>

    <!-- <div v-if="navigation" class="title-bar page-title-bar bg-white border-b border-gray">
      <div class="flex justify-between items-center container mx-auto py-4">

        <div class="w-2/12 pl-2 md:p-0">
          <template v-if="navigationControls.back">
            <div class="text-sm cursor-pointer" @click="$router.go(-1)">
              <template v-if="navigationControls.back.text">{{ navigationControls.back.text }}</template>
              <template v-else>Zurück</template>
            </div>
          </template>
        </div>
        <Headline :title="navigation.title.text" align="center" :size="navigation.title.size ? navigation.title.size : 'S'" class="w-10/12"/>
        <div class="w-2/12"></div>
      </div>
    </div> -->

    <div>
      <component v-if="layout" :is="layout">
        <template slot="body">
          <slot></slot>
        </template>
      </component>
    </div>
    <!-- <ComponentsBuilder schema="footerCookies"></ComponentsBuilder> -->
  </div>
  <div class="page__footer">
    <ComponentsBuilder schema="footer"></ComponentsBuilder>
  </div>
</div>
</template>

<script>
import {
  mapActions,
  mapGetters
} from 'vuex'
import layoutComponents from './layouts'
import PageNavigation from './components/page-navigation'

export default {
  name: 'Page',
  components: {
    ...layoutComponents,
    PageNavigation
  },
  props: {
    classes: String,
    layout: {
      type: String,
      default: 'standard'
    },
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: Object,
      default: () => {}
    },
    menu: {
      type: Array,
      default: () => []
    },
    navigation: Object,
    customNavigation: {
      type: String,
      default: 'navigation2Rows'
    }
  },
  computed: {
    ...mapGetters({
      basket: 'basket/getBasket'
    }),
    navigationControls() {
      let controls = {}

      if (this.navigation.controls) {
        this.navigation.controls.forEach((control, i) => {
          controls[control.key] = {}
        })
      }

      return controls
    },
  },
};
</script>
