<template>
  <div class="c-fixed-sidebar">
    <div class="fixed-sidebar">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FixedSidebar',
};
</script>

<style lang="scss">
.c-fixed-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 512px;
  z-index: 10;
  height: 100%;
  .fixed-sidebar {
    height: 100%;
    @apply bg-white;
    @apply border-none;
    @apply rounded-none;
    @apply shadow-lg;
    @apply px-16;
  }
}
</style>
