<template lang="html">
  <div class="o-sticky-footer">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'OStickyFooter',
};
</script>

<style lang="scss" scoped>
.o-sticky-footer {
  position: sticky;
  bottom: 0;
  @apply p-4;
  @apply border-t;
  @apply bg-white;
}
</style>
