import client from 'client';

export default {
  fetchProductByKey(key) {
    return client.get(`v1/products/${key}`);
  },
  fetchCategoryByKey(key) {
    return client.get(`v1/products/categories/${key}`);
  },
};
