<template>
<div v-if="product" class="w-full h-full flex items-stretch pb-64 md:mt-32">

  <div class="w-full container h-full mx-auto flex flex-col">

    <div class="flex flex-wrap md:flex-no-wrap" v-if="product">

      <!-- Desktop Gallery -->
      <div class="hidden w-full md:w-1/3 flex-none md:block" style="width-: 490px;">
        <div class="product__badge-group badge-group absolute z-10">
          <Badge :class="'badge--product badge--product-' + badge.key" :text="badge._text" v-for="badge in variant._badges"/>
        </div>
        <el-carousel ref="gallery" class="w-full" :interval="5000" arrow="always" height="550px" :autoplay="false" @change="onChangeGalleryImage" trigger="click">
          <el-carousel-item v-for="(image, index) in product.images" :key="index">
            <img class="px-16 py-8 md:p-0" :src="image.url" style="height: auto;width: 100%;">
          </el-carousel-item>
        </el-carousel>

        <div class="flex flex-center mt-2">
          <div class="border border-gray rounded-md p-2 m-auto w-auto inline-block flex" style="height: 100px;overflow: auto;">
            <div @click="onChangeGalleryImage(index)" class="border border-white hover:border-gray rounded-md h-full mx-1 cursor-pointer flex-none" :class="{'border-black':true}" style="width: 82px;" v-for="(image, index) in product.images" :key="index">
              <div class="h-full w-full rounded-md" :style="`background:url('${image.url}');background-position: center;    background-size: cover;background-repeat: no-repeat;`">
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Mobile Gallery -->
      <el-carousel class="md:hidden w-full" :interval="10000" type="card" height="300px" :autoplay="false">
        <el-carousel-item v-for="(image, index) in product.images" :key="index" style="display: flex;justify-content: center;">
          <img v-if="displayedImage" class="px--16 py.-8 md:p-0" :src="image.url" style="height: inherit;">
          <!-- <div class="h-full w-full rounded-md" :style="`background:url('${image.url}');background-position: center;    background-size: cover;background-repeat: no-repeat;`"></div> -->
        </el-carousel-item>
      </el-carousel>

      <div class="p-4 md:pl-12">
        <Headline>{{ product.name }}</Headline>
        <div>{{ product.key }}</div>

        <!-- <div>
          <div class="text-2xl mt-4">{{ variant._price }}</div>
          <el-input-number style="width: 90px;" class="mt-2" size="mini" :min="1" v-model="quantity"/>
        </div> -->

        <Button class="product__add-to-cart-button mt-6 md:w-auto" fullsize @click="onClickAddToBasket()" :loading="loadingAddToBasket">
          {{ $t('views.product.addToCart_button') }}
        </Button>

        <div class="mt-16 overflow-auto">
          <el-tabs>
            <el-tab-pane v-for="description in product.description" :label="description.title ? description.title : $t('views.product.description')"><p class="px-2-" v-html="description.content"/></el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import moment from 'moment';
import {
  mapActions,
  mapGetters
} from 'vuex';
import {
  TimelineLite
} from 'gsap'

export default {
  name: 'Product',
  created() {
    this.fetchProductByKey(this.$route.params.key).then((response) => {
      this.product = response.data
      this.variant = this.product.variants[0]
      this.selectedVariant = this.variant.id

      this.displayedImage = this.product.images[0]
    })
  },
  data() {
    return {
      selectedVariant: null,
      product: null,
      variant: null,
      quantity: 1,
      displayedImage: null,
      loadingAddToBasket: false
    };
  },
  computed: {
    navigation() {
      return {
        title: {
          text: this.product.name
        }
      }
    },
  },
  methods: {
    ...mapActions({
      fetchProductByKey: 'product/fetchProductByKey',
      addItemToBasket: 'basket/addItem'
    }),
    onClickAddToBasket() {
      this.loadingAddToBasket = true
      this.addItemToBasket({
        basketId: this.$store.state.basket.basketId,
        item: {
          variantId: this.selectedVariant,
          quantity: parseInt(this.quantity)
        }
      }).then(() => {
        this.loadingAddToBasket = false
      })
    },
    onChangeGalleryImage(gallery_index) {
      this.$refs.gallery.setActiveItem(gallery_index)
    }
  }
};
</script>

<style lang="scss">
.mailbox-mail-list {
    position: relative;
    background: #fff;
    &::-webkit-scrollbar {
        display: none;
    }

    &.is-disabled {
        overflow: hidden;
        &:after {
            content: '';
            background: rgba(232, 232, 232, 0.5);
            top: 0;
            bottom: 0;
            position: absolute;
            left: 0;
            display: inline-block;
            width: 100%;
            height: 100%;
        }
    }
}
.ui-list-item__sub {
    .mailbox-list-item {
        border: none;
        &:hover {
            @apply rounded-l-md;
        }
        &.is-active {
            @apply rounded-l-md;
        }
    }
}
</style>
