import client from 'client'
import { AuthApi, UserApi } from 'api-client'
import { setCookie, getCookie } from '../../helpers/cookie'
import uniqid from 'uniqid'
import cacheService from '../../services/cache'

const store = {
  namespaced: true,
  state: {
    authenticated: false,
    accessToken: localStorage.getItem('accessToken') || null,
    guestToken: getCookie('guestToken') || null
  },
  getters: {},
  mutations: {
    authenticate: (state, token) => {
      localStorage.setItem('accessToken', token);
      state.accessToken = token;
      client.defaults.headers.common.Authorization = `Bearer ${token}`;
      state.authenticated = true;
    },
    signOut: (state) => {
      localStorage.removeItem('accessToken');
      state.accessToken = null;
      state.authenticated = false;
      /**
       * Summary: Clear checkout form data cache
       */
      cacheService.removeItem('checkout_shipping_address_form')
      cacheService.removeItem('checkout_billing_address_form')

      location.reload()
    },
    setGuestToken: (state) => {
      const guestToken = uniqid()

      state.guestToken = guestToken

      setCookie('guestToken', guestToken)
    },
    clearSession: (state) => {
      localStorage.removeItem('accessToken');
      state.accessToken = null;
      state.authenticated = false;
    }
  },
  actions: {
    register: (state, payload) => new Promise((resolve, reject) => {
      AuthApi.register(payload)
        .then((response) => {
          state.commit('authenticate', response.data.user.token);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    }),
    login: ({ state, commit }, payload) => new Promise((resolve, reject) => {
      AuthApi.login(payload)
        .then((response) => {
          commit('authenticate', response.data.user.token);
          commit('user/updateUser', response.data.user, { root: true });
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    }),
    authenticate: ({ state, commit }) => new Promise((resolve, reject) => {
      if (state.accessToken) {
        AuthApi.authenticate()
          .then((response) => {
            commit('authenticate', response.data.user.token);
            commit('user/updateUser', response.data.user, { root: true });
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        reject({ error: 'no_token' });
      }
    }),
    signOut: ({ commit, dispatch }) => new Promise((resolve, reject) => {
      commit('user/clearUser', null, { root: true })
      commit('signOut');

      dispatch('basket/deleteSession', null, { root: true }).then(() => {
        resolve()
      })
    }),
    requestPasswordReset: (state, payload) => {
      new Promise((resolve) => {
        AuthApi.requestPasswordReset(payload).then((result) => {
          resolve(result);
        });
      });
    },
    createGuestSession: ({ state, commit, dispatch }) => new Promise((resolve, reject) => {
      if (!state.guestToken) {
        commit('setGuestToken')
        resolve()
      }
    }),
    clearSession: ({ commit, dispatch }) => new Promise((resolve, reject) => {
      commit('user/clearUser', null, { root: true })
      commit('clearSession');
    }),
  },
};

export default store;
