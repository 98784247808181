import {
  PaymentApi
} from 'api-client';

const store = {
  namespaced: true,
  state: {
    payment: null,
    paymentOptions: []
  },
  getters: {
    getPaymentOptions: (state) => {
      return state.paymentOptions.map(option => ({
        ...option,
        translation: option.translation ? option.translation : {}
      }))
    }
  },
  mutations: {
    updateOptions: (state, payload) => {
      state.paymentOptions = payload
    },
    updatePayment: (state, payload) => {
      state.payment = payload
    },
  },
  actions: {
    fetchPayment: (state, payload) => new Promise((resolve, reject) => {
      PaymentApi.fetchPayment(payload)
        .then((response) => {
          state.commit('updateOptions', response.data)

          const selected_payment_method = response.data.find(payment_method => payment_method.selected)

          if (selected_payment_method) {
            state.commit('updatePayment', selected_payment_method)
          }

          resolve(response)
        })
        .catch((error) => {
          reject(error)
        });
    }),
  },
};

export default store
