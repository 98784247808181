<template>
  <Dropdown ref="dropdown" class="nav-item-cart action-menu__dropdown" :items="menu" :fixed="dropdownFixed" :always-show="showDropdown" dropdown-key="item.key">
    <Icon icon="cart"/>
    <!-- v-if="basket.quantityCount" -->
    <span ref="chip" class="absolute right-0 top-0 rounded-full h-4 bg-primary z-10 text-white font-bold transform scale-0" style="line-height: 17px;text-align: center;font-size: 9px;padding: 0 3px;width: auto;min-width: 16px;">
      {{ basket.quantity_count }}
    </span>
  </Dropdown>
</template>

<script>
import gsap from 'gsap'
import {
  mapGetters,
  mapActions
} from 'vuex'

export default {
  name: 'ShopCategoriesList',
  props: {
    dropdownFixed: Boolean,
    alwaysShow: Boolean,
    iconHeight: {
      type: Number,
      default: 32
    }
  },
  watch: {
    'basket.quantity_count': {
      handler(n, o) {
        if (this.basket.quantity_count === 0) {
          this.hideChip()
          this.$refs.dropdown.hide()
          return
        }

        if (this.basket.quantity_count >= 1 && !this.chip_animation) {
          this.showChip(0.1)
          this.$refs.dropdown.show()
          return
        }

        /**
         * Summary: Play quantity pop animation
         *
         * Description: If quantity bigger than 1 AND main animation exists THEN play pop animation
         */
        if (this.basket.quantity_count > 1 && this.chip_animation) {
          this.popChip()
          this.$refs.dropdown.show()
        }
      }
    }
  },
  created() {
    this.fetchBasket()
  },
  mounted() {
    if (this.basket.quantity_count >= 1) {
      this.showChip()
    }
  },
  computed: {
    ...mapGetters({
      basket: 'basket/getBasket'
    }),
    showDropdown() {
      return this.alwaysShow && this.basket.items.length ? true : false
    },
    basketItems() {
      return this.basket.items.map(item => ({
        key: 'product',
        name: item.name,
        classes: 'pl-6 mb-2',
        component: {
          key: 'BasketItem',
          attributes: {
            itemId: item.id,
            name: item.variant.name,
            quantity: item.quantity,
            price: item.variant.price,
            _price: item.variant._price,
            image: item.variant.images[0],
          }
        }
      }))
    },
    menu() {
      if (this.basket.items.length) {
        return [{
            key: 'checkout',
            name: 'Zur Kasse',
            classes: 'pl-6 py-4 mt-8 mb-8',
            component: {
              key: 'Button',
              slotContent: 'Zur Kasse',
              attributes: {
                class: 'nav-item__go-to-checkout-button',
                fullsize: true,
                route: 'checkout'
              }
            },
          },
          // {
          //   component: {
          //     key: 'BasketItemList'
          //   }
          // },
          ...this.basketItems,
          {
            name: `Zwischensumme: ${this.basket._total}`,
            classes: 'mb-4'
          }
        ]
      }

      return [
        {
          name: 'Dein Warenkorb ist leer',
          classes: 'text-sxl my-4'
        }
      ]
    },
  },
  methods: {
    ...mapActions({
      fetchBasket: 'basket/fetchBasket'
    }),
    popChip() {
      gsap.from(this.$refs.chip, {duration: 0.2, scale: 1.4})
      gsap.to(this.$refs.chip, {duration: 0.2, scale: 1}, 0.3)
    },
    showChip(duration = 0) {
      this.chip_animation = gsap.to(this.$refs.chip, {duration, scale: 1})
    },
    hideChip() {
      if (this.chip_animation) {
        this.chip_animation.reverse()
        this.chip_animation = null
      }
    }
  },
  data() {
    return {
      chip_animation: null
    }
  }
}
</script>

<style lang="scss">
.nav-item-cart{
  .dropdown-body {
    width: 300px;
  }
}
</style>
