<template>
<Page>
  <ComponentsBuilder schema="shop"></ComponentsBuilder>
</Page>
</template>

<script>
import {
  mapActions,
  mapGetters
} from 'vuex';

export default {
  name: 'Shop'
};
</script>
