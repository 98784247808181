<template>
  <ul class="c-page-menu">
    <router-link class="page-menu__item" :to="{ name: 'sheets' }">Sheets</router-link>
    <router-link class="page-menu__item" :to="{ name: 'customers' }">Customers</router-link>
    <router-link class="page-menu__item" :to="{ name: 'settings' }">Settings</router-link>
  </ul>
</template>

<script>
export default {
  name: 'PageMenu',
};
</script>

<style lang="scss">
.c-page-menu {
  display: flex;
  @apply -mx-2;
  .page-menu__item {
    cursor: pointer;
    user-select: none;
    @apply px-2;
    @apply py-1;
    @apply rounded;
    @apply mr-4;

    &:hover {
      @apply bg-gray-hover;
    }

    &.is-active {
      @apply bg-gray-hover;
    }

    &.router-link-exact-active {
      @apply bg-alpha;
      @apply text-white;
      @apply font-bold;
    }
  }
}
</style>
