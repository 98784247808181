<template>
  <div class="o-input relative" :class="{'as-placeholder':labelAsPlaceholder && label}" v-click-outside="onClickOutside">
    <OLabel ref="label" class="input__label" :class="{'as-placeholder':labelAsPlaceholder, 'as-placeholder--fixed':(labelAsPlaceholder && !labelAnimation && value), 'has-error':field_error&&config_error_in_label&&value}" v-show="label">
      <template v-if="field_error&&config_error_in_label&&value">
        <template v-if="typeof field_error == 'string'">
          {{ $t(`errors.${field_error}.message`) }}
        </template>
        <template v-else>
          <div v-for="errorKey in field_error.slice(0,1)" v-bind:key="errorKey">
            {{ $t(`errors.${errorKey}.message`) }}
          </div>
        </template>
      </template>
      <template v-else>
        {{ label }}
      </template>
    </OLabel>
    <img v-if="icon" src="https://www.flaticon.com/premium-icon/icons/svg/2985/2985024.svg" style="height: 24px;position: absolute;top: 13px;right: 16px;">
    <input
      :class="classes"
      v-bind="$attrs"
      :value="value"
      :type="fieldConfig.type"
      :placeholder="fieldConfig.placeholder"
      :name="fieldConfig.name"
      :min="fieldConfig.min"
      :max="fieldConfig.max"
      :autocomplete="fieldConfig.autocomplete"
      :readonly="readOnly"
      :disabled="disabled || loading"
      @input="onInput"
      @change="onChange"
      @blur="onBlur"
      @click="onClick"
      @mousedown="onMouseDown"
      ref="field"
      v-mask="fieldConfig.mask"
    />
    <div class="text-red-600 text-xs" v-if="field_error && !config_error_in_label">
      <template v-if="typeof field_error == 'string'">
        {{ $t(`errors.${field_error}.message`) }}
      </template>
      <template v-else>
        <div v-for="errorKey in field_error.slice(0,1)" v-bind:key="errorKey">
          {{ $t(`errors.${errorKey}.message`) }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'
import fieldSchema from './field-schema.json'
import ClickOutside from 'vue-click-outside'

const INPUT_SIZES = {
  s: 'px-2 py-1 text-sm',
  m: 'px-4 py-3',
  l: 'px-4 py-3'
};

export default {
  name: 'OInput',
  inheritAttrs: false,
  model: {
    prop: 'value',
    event: 'update',
  },
  props: {
    value: [String, Number],
    error: [String, Array, Object],
    invalid: Boolean,
    label: String,
    labelAs: String,
    labelAsPlaceholder: {
      type: Boolean,
      default: true
    },
    type: String,
    readOnly: Boolean,
    disabled: Boolean,
    size: {
      type: String,
      default: 'm',
    },
    schemaKey: {
      type: String,
      defaut: '',
    },
    loading: {
      type: Boolean
    },
    icon: String,
    mask: String
  },
  created() {
    this.setFieldConfig();

    // gsap.to('.input__label', 0.15, {
    //   // fontSize: 11,
    //   // lineHeight: '20px'
    //   scale: 0.75,
    //   transformOrigin: 'left',
    //   // x: -5,
    //   y: -10
    // })

    if (this.value) {
      this.animateLabel()
    }
  },
  watch: {
    value: {
      handler() {
        if (this.value) {
          this.animateLabel()
        }
      },
      immediate: true
    },
    error() {
      this.field_error = this.error
    }
  },
  computed: {
    classes() {
      return [
        'input',
        INPUT_SIZES[this.size],
        // `input border border-gray-300 outline-none py-2 px-4 w-full as-size-${this.size} ${this.error ? 'has-error' : ''}`,
        this.field_error ? 'is-invalid' : '',
        // this.loading ? 'is-loading' : '',
      ];
    },
  },
  methods: {
    setFieldConfig() {
      if (fieldSchema[this.schemaKey]) {
        this.fieldConfig = fieldSchema[this.schemaKey].attrs;
      }

      if (this.type) {
        this.fieldConfig.type = this.type;
      }
      if (this.placeholder) {
        this.fieldConfig.placeholder = this.placeholder;
      }
      if (this.name) {
        this.fieldConfig.name = this.name;
      }
    },
    onChange(event) {
      const { value } = event.currentTarget;
      this.$emit('update', String(value).trim());
      this.$emit('change', event, String(value).trim());

      this.field_error = null
    },
    onInput(event) {
      const { value } = event.currentTarget;
      this.$emit('update', String(value));
      this.$emit('input', event, String(value));
    },
    onBlur(event) {
      const { value } = event.currentTarget;
      this.$emit('blur', event, String(value).trim());
    },
    onClick() {
      this.$emit('click')
    },
    onMouseDown() {
      this.animateLabel()
    },
    onClickOutside() {
      if (!this.value && this.labelAnimation) {
        this.labelAnimation = gsap.to(this.$refs.label.$el, 0.15, {
          scale: 1,
          transformOrigin: 'left',
          y: 0
        })
      }
    },
    animateLabel() {
      if (this.$refs.label && !this.value) {
        this.labelAnimation = gsap.to(this.$refs.label.$el, 0.15, {
          scale: 0.75,
          transformOrigin: 'left',
          y: -10
        })
      }
    }
  },
  data() {
    return {
      fieldConfig: {},
      labelAnimation: null,
      config_error_in_label: true,
      field_error: null
    }
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style src="./input.scss" lang="scss"></style>
