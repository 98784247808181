<template>
  <List class="basket__item-list" v-if="basket && basket.items.length" :items="basketItems" title="Warenkorb" shadow/>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BasketItemList',
  computed: {
    ...mapGetters({
      basket: 'basket/getBasket'
    }),
    basketItems() {
      return this.basket.items.map(item => ({
        id: item.id,
        image: item.variant.images[0].url,
        additional_lines: [
          {
            text: item.variant.name,
            classes: 'text-xs overflow-hidden'
          },
          {
            text: item.variant._price,
            classes: 'text-xs font-semibold'
          },
          {
            text: 'Anzahl ' + item.quantity,
            classes: 'text-sm'
          }
        ]
      }))
    }
  }
}
</script>

<style lang="scss">
  .basket__item-list {
      margin-bottom: 8px;
    .list__item {
      padding: 12px 8px 0px 0px;
    }
  }
</style>
