<template>
  <component
    :is="getElementByType"
    :class="classes"
    @click="onClick">
    <slot />
  </component>
</template>

<script>
const LINK_TYPES = new Map(
  Object.entries({
    route: 'router-link',
    link: 'a',
  }),
);
const LINK_PREFIX = 'o-link font-medium cursor-pointer inline-block';
const HEADLINE_SIZES = {
  m: 'text-2xl',
  l: 'text-4xl',
  xl: 'text-6xl',
};

export default {
  name: 'o-headline',
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: 'link',
      validator: value => LINK_TYPES.has(value),
    },
    size: {
      default: 'm',
      validator: value => HEADLINE_SIZES.hasOwnProperty(value),
    },
  },
  computed: {
    getElementByType() {
      const getEl = LINK_TYPES.get(this.type);
      return getEl || this.type.default;
    },
    classes() {
      return `${LINK_PREFIX}`;
    },
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>
