<template>
  <div>
    <input type="range" min="0" max="100" step="1" :value="value" @input="onChange">
  </div>
</template>

<script>
export default {
  name: 'ToolRangeSlider',
  props: {
    value: String
  },
  model: {
    prop: 'value',
    event: 'update',
  },
  methods: {
    onChange(event) {
      const { value } = event.currentTarget;
      this.$emit('update', value);
      this.$emit('change', event, value);
    }
  }
}
</script>
