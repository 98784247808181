<template>
<div>
  <div class="builder__nav">
    <div class="flex items-center text-white px-4 b-text-gray flex-none" style="color: #8e8e91;width: 400px;">
      Perdia Builder <span class="text-xs ml-2">Beta v.0.0.1</span>
    </div>
    <div class="flex items-center text-center">
      <ActionMenu class="b-action-menu" :groups="menu"></ActionMenu>
    </div>
    <div class="flex items-center justify-end px-8 flex-1">
      <div class="builder-button builder-button--secondary">Preview</div>
      <div class="builder-button ml-2" :class="{'builder-button--secondary':!changed}">Speichern</div>
    </div>
  </div>
  <div class="builder">
    <div class="builder__toolbar b-scrollbar" style="width: 400px;overflow: auto;background:#222328;">
      <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick"></el-tree>

      <div class="b-component-title mb-8">
        <div class="b-input-group">
          <label class="b-label b-label--input">Component: <template v-if="componentSchema">{{ componentSchema.name }}</template></label>
          <input v-model="schema" class="b-input focus:outline-none px-2" style="">
        </div>
        <div class="b-input-group">
          <label class="b-label b-label--input">Classes</label>
          <input v-model="componentSchema.classes" class="b-input focus:outline-none px-2" style="">
        </div>
      </div>
      <template v-if="componentSchema && componentSchema.rows">
        <div class="b-row" v-for="(row, index) in componentSchema.rows">
          <label class="b-label b-label--row">Row {{ index + 1 }}</label>
          <label class="b-label">Classes</label>
          <div class="b-input-group">
            <input v-model="row.classes" class="b-input focus:outline-none px-2" style="">
          </div>

          <template v-if="row.cols">
            <div class="b-row pl-4" v-for="(col, index) in row.cols">
              <label class="b-label b-label--col"><span style="color: #535357;">Col {{ index + 1 }}</span><template v-if="col.component && col.component.key">: {{ col.component.key }}</template></label>
              <div class="b-input-group" v-if="col.component">
                <label class="b-label b-label--input">Component</label>
                <input v-model="col.component.key" class="b-input focus:outline-none px-2" style="">
              </div>
              <div class="b-input-group">
                <label class="b-label b-label--input">Classes</label>
                <input v-model="col.classes" class="b-input focus:outline-none px-2" style="">
              </div>

              <label class="b-label">Additional attributes</label>
              <div class="b-input-group" v-if="col.component && col.component.attributes && col.component.attributes.url">
                <label class="b-label b-label--input">URL</label>
                <input v-model="col.component.attributes.url" class="b-input focus:outline-none px-2" style="">
              </div>
              <div class="b-input-group" v-if="col.component && col.component.attributes && col.component.attributes.height">
                <div class="flex">
                  <label class="b-label b-label--input">Size</label>
                  <div class="relative">
                    <input @keyup.up="col.component.attributes.height++" @keyup.down="col.component.attributes.height--" v-model="col.component.attributes.height" class="b-input focus:outline-none px-2">
                    <span class="b-text-dark-gray absolute" style="right: 8px;top: 0;line-height: 34px;">W</span>
                  </div>
                  <div>
                    <input v-model="col.component.attributes.height" class="b-input focus:outline-none px-2 ml-2">
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </template>
    </div>
    <div class="b-scrollbar flex-1 overflow-auto relative grab-bing" :style="`background:${themeColor};`" v-dragscroll>
      <div class="h-full pt-64" style="width: 3000px;height: 3000px;">
        <div :style="`transform: scale(${zoom/100});`">
          <ComponentsBuilder ref="currentComponent" :schema="schema"></ComponentsBuilder>
        </div>
      </div>
      <div class="fixed mx-auto sleft-0 bottom-0 p-8 flex">
        <!-- <input value="+" class="b-input focus:outline-none px-2" style="width: 34px;text-align: center;"> -->
        <div class="relative">
          <span @click="zoom = zoom + 25" class="absolute left-0 top-0 pl-3 cursor-pointer" style="line-height: 34px;color: #8e8e91;user-select: none;">+</span>
          <input v-model="zoom" class="b-input focus:outline-none px-2 w-24 text-center" style="">
          <span @click="zoom = zoom - 25" class="absolute right-0 top-0 pr-3 cursor-pointer" style="line-height: 34px;color: #8e8e91;user-select: none;">-</span>
        </div>

        <div @click="toggleTheme()" class="b-input focus:outline-none px-2 w-24 text-center ml-2 cursor-pointer" :style="`width: 34px;background:${themeColorInvert};`"></div>
        <!-- <input value="-" class="b-input focus:outline-none px-2" style="width: 34px;text-align: center;"> -->
      </div>
    </div>
    <div class="w-64 pt-16">
      <label class="b-label">
        <span>Pages | </span>
        <span>Components | </span>
        <span>Ui</span>
      </label>

      <label class="b-label text-center">Components</label>

      <template v-for="(component, key) in componentSchemas">
        <div @click="schema=key" class="">
          <div class="bg-white h-32 mx-4 mt-2 rounded-md cursor-pointer" style="background: url('https://tailwindui.com/img/category-thumbnails/sections-header.svg');    background-position: top;
    background-size: cover;
    border: 1px solid #e5e7eb;">
            <!-- <ComponentsBuilder :schema="key"></ComponentsBuilder> -->
          </div>
          <label class="b-label mt-1">{{ component.name }}</label>
        </div>
      </template>
    </div>
  </div>
</div>
</template>

<script>
import _ from 'lodash'
import {
  dragscroll
} from 'vue-dragscroll'

import componentSchemas from '../../config/components'

export default {
  name: 'AdminShopBuilder',
  watch: {
    schema() {
      this.componentSchema = componentSchemas[this.schema]

      this.$refs.currentComponent.$el.style.position = 'unset';
    },
  },
  created() {
    this.componentSchema = componentSchemas[this.schema]
  },
  computed: {
    data() {
      return this.componentSchema.rows.map((row, i) => ({
        label: 'Row ' + (i + 1),
        children: row.cols ? row.cols.map((col, i) => ({
          label: 'Col ' + (i + 1) + ':' + (col.component ? col.component.key : ''),
        })) : []
      }))
      return [{
        label: 'Level one 1',
        children: [{
          label: 'Level two 1-1',
          children: [{
            label: 'Level three 1-1-1'
          }]
        }]
      }, {
        label: 'Level one 2',
        children: [{
          label: 'Level two 2-1',
          children: [{
            label: 'Level three 2-1-1'
          }]
        }, {
          label: 'Level two 2-2',
          children: [{
            label: 'Level three 2-2-1'
          }]
        }]
      }, {
        label: 'Level one 3',
        children: [{
          label: 'Level two 3-1',
          children: [{
            label: 'Level three 3-1-1'
          }]
        }, {
          label: 'Level two 3-2',
          children: [{
            label: 'Level three 3-2-1'
          }]
        }]
      }]
    },
    defaultProps: () => ({
      children: 'children',
      label: 'label'
    }),
    menu() {
      return [{
        items: [{
            name: 'file',
            text: 'File',
            dropdown: {
              items: [{
                  key: 'new_component',
                  name: 'New component',
                },
                {
                  key: 'open_component',
                  name: 'Open...',
                }
              ]
            }
          },
          {
            name: 'edit',
            text: 'Edit',
            dropdown: {
              items: [{
                  key: 'undo',
                  name: 'Undo',
                },
                {
                  key: 'redo',
                  name: 'Redo',
                }
              ]
            }
          },
          {
            name: 'view',
            text: 'View',
            dropdown: {
              items: [{
                key: 'fullscreen',
                name: 'Fullscreen',
              }]
            }
          }
        ]
      }]
    },
    themeColor() {
      if (this.theme === 'dark') {
        return '#1e1f23'
      }
      if (this.theme === 'light') {
        return '#fff'
      }
    },
    themeColorInvert() {
      if (this.theme === 'dark') {
        return '#fff'
      }
      if (this.theme === 'light') {
        return '#1e1f23'
      }
    }
  },
  data() {
    return {
      schema: 'navigationDemo',
      componentSchema: null,
      componentSchemas,
      zoom: 100,
      changed: false,
      theme: 'dark'
    }
  },
  methods: {
    toggleTheme() {
      if (this.theme === 'dark') {
        this.theme = 'light'
        return
      }
      if (this.theme === 'light') {
        this.theme = 'dark'
      }
    }
  },
  directives: {
    'dragscroll': dragscroll
  },
};
</script>

<style lang="scss">
.builder {
    background: #1e1f23;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: between;
    // color: #8e8e91;
}

.b-component-title {
    color: #8e8e91;
    @apply text-lg;
    // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.b-input {
    // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: #8e8e91;
    background: #222428;
    border: 1px solid #343439;
    color: #8e8e91;
    outline: none;
    width: 100%;
    line-height: 32px;
    @apply text-sm;
    @apply px-2;
    @apply rounded-md;
}

.b-input-group {
    @apply px-4;
    @apply mb-2;
}

.b-label {
    // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: #8e8e91;
    width: 100%;
    display: block;
    @apply text-sm;
    @apply mr-2;
    @apply px-4;
    @apply mb-1;
    &--row {
        background: #26272d;
        @apply mb-2;
    }
    &--col {
        background: #2c2638;
        @apply mb-2;
    }
    &--input {
        @apply px-0;
    }
}

.b-row {
    @apply mb-2;
}

.b-text-gray {
    color: #8e8e91;
}

.b-text-dark-gray {
    color: #343439;
}

.builder__toolbar {
    @apply pt-20;
}

.b-scrollbar::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
}

.b-scrollbar::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.b-scrollbar::-webkit-scrollbar-thumb {
    background-color: #26272d;
}

.builder__nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #1b1b20;
    display: flex;
    z-index: 10;
    @apply h-16;
}

.builder-button {
    background: #00cca5;
    border: 1px solid #00cca5;
    color: white;
    line-height: 32px;
    width: fit-content;
    padding: 0 8px;
    cursor: pointer;
    @apply rounded-md;
    @apply text-sm;
    // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

    &--secondary {
        border: 1px solid #343439;
        color: #8e8e91;
        background: transparent;
    }
}

.grab-bing {
    cursor: default;
}

.grab-bing:active {
    cursor: grabbing;
}

.b-action-menu {
    color: #8e8e91;

    .action-menu__dropdown {
        &:active {
            background: #25282c;
        }
        .dropdown-body {
            background: #343539;
        }
    }

    .ui-dropdown.is-active {
        background: #343539;
    }

    .ui-dropdown__item {
        &:hover {
            background: #222428;
            cursor: pointer;
        }
    }
}
</style>
