import Badge from './badge.vue'
import Button from './button.vue'
import Card from './card.vue'
import FooterCol from './footer-col.vue'
import Headline from './headline.vue'
import Icon from './icon.vue'
import Link from './link.vue'
import Loading from './loading.vue'
import List from './list.vue'
import Modal from './modal.vue'
import Paragraph from './paragraph.vue'

export default {
  Badge,
  Button,
  Card,
  FooterCol,
  Headline,
  Icon,
  Link,
  Loading,
  List,
  Modal,
  Paragraph
}
