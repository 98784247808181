<template>
<div class="container mx-auto mt-8 md:mt-32 flex">
  <div v-if="category">
    <Headline class="text-center md:text-left">{{ category.name }}</Headline>

    <div class="mt-4 flex flex-wrap relative justify-center md:justify-start">
      <template v-for="product in category.products">
        <ProductItem v-bind="product" :product-key="product.key" class="mb-6 mr-2" style="width: 250px;" @click="onClickProduct(product.key)"></ProductItem>
      </template>
    </div>
  </div>

  <Modal v-if="product" @close="onCloseProductView">
    <div class="text-right">
      <div @click="onCloseProductView" style="display: inline-block;background: #EEEEEE;border-radius: 2px;padding: 4px 8px;">Schließen</div>
    </div>
    <Headline>{{ product.name }}</Headline>
    <div>{{ product.key }}</div>
    <div>{{ product.variants[0]._price }}</div>

    <!-- <img class="md:py-2" :src="product.image_url" style="height: 400px;width: auto;"> -->

    <div class="flex overflow-auto mt-2">
      <img v-for="image in product.images" class="md:py-2 mr-2" :src="image.url" style="height: 400px;width: auto;">
    </div>

    <!-- <OInput class="text-center mt-4" v-model="quantity" style="width: 60px;" schema-key="product-quantity" /> -->

    <Button class="product__add-to-cart-button mb-6 md:w-auto mt-4" fullsize @click="onClickAddToBasket(product.variants[0].id)" :loading="loadingAddToBasket">
      {{ $t('views.product.addToCart_button') }}
    </Button>

    <div class="mt-16">
      {{ $t('views.product.description') }}
      <div class="mt-4 text-sm">
        <p v-html="product.description"/>
      </div>
    </div>
  </Modal>

  <div class="w-1/4" v-if="false">
    Filter
  </div>
</div>
</template>

<script>
import {
  mapActions,
  mapGetters
} from 'vuex';

export default {
  name: 'ShopCategory',
  created() {
    this.fetchCategoryByKey(this.$route.params.category)
  },
  watch: {
    '$route': {
      handler(newVal, oldVal) {
        if (oldVal && oldVal.params.category !== newVal.params.category) {
          this.fetchCategoryByKey(this.$route.params.category)
        }

        if (this.$route.params.product_key) {
          this.fetchProductByKey(this.$route.params.product_key).then((response) => {
            this.product = response.data
            // this.variant = this.product.variants[0]
            // this.selectedVariant = this.variant.id
            //
            // this.displayedImage = this.product.images[0]
          })
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters({
      category: 'product/getCategory'
    }),
  },
  methods: {
    ...mapActions({
      fetchCategoryByKey: 'product/fetchCategoryByKey',
      fetchProductByKey: 'product/fetchProductByKey',
      addItemToBasket: 'basket/addItem',
    }),
    onClickProduct(product_key) {
      this.$router.push({
        name: 'product',
        params: {
          key: product_key
        }
      })
      // this.$router.push({
      //   name: 'shop_category_product',
      //   params: {
      //     category: this.$route.params.category,
      //     product_key
      //   }
      // })
    },
    onClickAddToBasket(variant_id) {
      this.loadingAddToBasket = true
      this.addItemToBasket({
        basketId: this.$store.state.basket.basketId,
        item: {
          variantId: variant_id,
          quantity: 1
        }
      }).then(() => {
        this.loadingAddToBasket = false
      })
    },
    onCloseProductView() {
      this.product = null
      this.$router.push({
        name: 'shop_category',
        params: {
          category: this.$route.params.category
        }
      })
    }
  },
  data() {
    return {
      product: null,
      loadingAddToBasket: false,
    }
  }
};
</script>
