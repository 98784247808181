<template>
  <div class="c-select">
    <label class="w-full" v-if="label">{{ label }}</label>

    <select v-model="model.value" @change="onChange" :class="classes">
      <option :value="option.value" v-for="option in options">
        {{ option.name ? option.name : option.value }}
      </option>
    </select>
    <!-- <input
      :class="classes"
      v-bind="$attrs"
      :value="value"
      :readonly="readOnly"
      :disabled="disabled || loading"
      @input="onInput"
      @change="onChange"
      @blur="onBlur"
      ref="field"
    /> -->
    <div class="text-red-600" v-if="error">
      <template v-if="typeof error == 'string'">{{ error }}</template>
      <template v-else>
        <div v-for="message in error" v-bind:key="message">{{ message }}</div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Select',
  inheritAttrs: false,
  model: {
    prop: 'value',
    event: 'update',
  },
  props: {
    value: [String, Number],
    error: [String, Array, Object],
    invalid: Boolean,
    label: String,
    placeholder: String,
    type: String,
    readOnly: Boolean,
    disabled: Boolean,
    size: {
      type: String,
      default: 'm',
    },
    schemaKey: {
      type: String,
      defaut: '',
    },
    loading: {
      type: Boolean,
    },
    options: {
      type: Array,
      defaut: () => [],
    },
  },
  computed: {
    classes() {
      return [
        'block sm:max-w-xs w-full leading-6 appearance-none border border-gray-300 shadow-none bg-white rounded-md placeholder-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-alpha-500 px-4 py-3 font-medium',
      ];
    },
  },
  watch: {
    value() {
      this.model.value = this.value ? this.value : null;
    }
  },
  created() {
    this.model.value = this.value ? this.value : null;
  },
  methods: {
    onChange(event) {
      const { value } = event.currentTarget;
      this.$emit('update', value);
      this.$emit('change', event, value);
    },
    onInput(event) {
      const { value } = event.currentTarget;
      this.$emit('update', value);
      this.$emit('input', event, value);
    },
    onBlur(event) {
      const { value } = event.currentTarget;
      this.$emit('blur', event, value);
    },
  },
  data() {
    return {
      model: {
        value: ''
      },
      fieldConfig: {},
    };
  },
};
</script>
