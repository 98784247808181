<template>
  <div :class="classes">
    {{ message }}
    <slot />
  </div>
</template>

<script>
const HEADLINE_PREFIX = 'ui-alert';

export default {
  name: 'Alert',
  props: {
    type: {
      type: String,
      default: 'success',
    },
    message: {
      type: String,
      default: '',
    },
    sticky: Boolean,
  },
  computed: {
    classes() {
      return [
        `${HEADLINE_PREFIX}`,
        this.sticky && 'is-sticky',
        this.type && `as-type-${this.type}`,
      ];
    },
  },
};
</script>

<style lang="scss">
.ui-alert {
  @apply py-2;
  @apply px-3;
  @apply border;
  @apply rounded-md;
  @apply mb-2;
  &.is-sticky {
    position: sticky;
    top: 0;
    z-index: 1;
    @apply bg-white;
  }

  &.as-type {
    &-success {
      @apply bg-success-500;
      @apply border-success;
      @apply text-success-900;
    }
    &-warning {
      @apply bg-warning-300;
      @apply border-warning-500;
      @apply text-warning-900;
    }
    &-danger {
      @apply bg-danger-500;
      @apply border-danger;
      @apply text-danger-900;
    }
  }
}
</style>
