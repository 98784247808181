<template>
  <router-link :to="route">{{ text }}</router-link>
</template>

<script>
export default {
  name: 'Link',
  props: {
    text: String,
    route: Object
  },
}
</script>
