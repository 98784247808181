<template>
  <span :class="classes"><slot/></span>
</template>
<script>
export default {
  name: 'UiChip',
  computed: {
    classes() {
      return [
        'ui-chip',
      ];
    },
  },
};
</script>

<style src="./chip.scss" lang="scss"></style>
