import Vue from 'vue';
import Vuelidate from 'vuelidate';
import VueI18n from 'vue-i18n'
import Notifications from 'vue-notification';
import ElementUI from 'element-ui';
import { VueMaskDirective } from 'v-mask'
import axios from 'axios';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/assets/css/tailwind.css';
import '@/assets/css/core.css';
import '@/assets/css/core-variables.css';
import '@/assets/scss/main.scss';
import '@/assets/css/element.css';

import CoreUiComponents from '../lib/core-ui'

import '../lib/core-ui/core-ui-variables.css'

Vue.directive('mask', VueMaskDirective)

Object.keys(CoreUiComponents).forEach((component, i) => {
  Vue.component(component, CoreUiComponents[component])
})

import VuePageTransition from 'vue-page-transition'

Vue.use(VuePageTransition)

import {
  ActionMenu,
  Alert,
  Dropdown,
  OInput,
  OInputDate,
  UiInputSearch,
  OLink,
  OLabel,
  OModal,
  Navigation,
  OTextarea,
  OTimePicker,
  Page,
  PageCheckout,
  AdminPage,
  PageMenu,
  Select,
  SmartPage,
  FixedSidebar,
  UiIcon,
  UiChip,
  FormImage,
} from './03-objects';

import {
  Basket,
  BasketItem,
  ProductItem,
  FormGenerator,
  ComponentsBuilder
} from './02-components';

import {
  BasketItemList,
  ShopCategoriesDropdown,
  ShopCategoriesList,
  NavItemAdmin,
  NavItemLink,
  NavItemLogo,
  NavItemMobileMenu,
  NavItemSecureCheckout,
  NavItemShoppingCart,
  NavItemUser,
  Login,
  Register,
  UpdateAccountData
} from './components';

Vue.component('ActionMenu', ActionMenu);
Vue.component('Alert', Alert);
Vue.component('Dropdown', Dropdown);
Vue.component('OInput', OInput);
Vue.component('OInputDate', OInputDate);
Vue.component('UiInputSearch', UiInputSearch);
Vue.component('OLabel', OLabel);
Vue.component('OLink', OLink);
Vue.component('OModal', OModal);
Vue.component('Navigation', Navigation);
Vue.component('OTextarea', OTextarea);
Vue.component('OTimePicker', OTimePicker);
Vue.component('Page', Page);
Vue.component('PageCheckout', PageCheckout);
Vue.component('AdminPage', AdminPage);
Vue.component('PageMenu', PageMenu);
Vue.component('Select', Select);
Vue.component('SmartPage', SmartPage);
Vue.component('FixedSidebar', FixedSidebar);
Vue.component('Basket', Basket);
Vue.component('BasketItem', BasketItem);
Vue.component('ProductItem', ProductItem);
Vue.component('FormGenerator', FormGenerator);
Vue.component('ComponentsBuilder', ComponentsBuilder);
Vue.component('FormImage', FormImage);

Vue.component('UiIcon', UiIcon);
Vue.component('UiChip', UiChip);

Vue.component('BasketItemList', BasketItemList)
Vue.component('ShopCategoriesDropdown', ShopCategoriesDropdown)
Vue.component('ShopCategoriesList', ShopCategoriesList)
Vue.component('NavItemAdmin', NavItemAdmin)
Vue.component('NavItemLink', NavItemLink)
Vue.component('NavItemLogo', NavItemLogo)
Vue.component('NavItemMobileMenu', NavItemMobileMenu)
Vue.component('NavItemSecureCheckout', NavItemSecureCheckout)
Vue.component('NavItemShoppingCart', NavItemShoppingCart)
Vue.component('NavItemUser', NavItemUser)
Vue.component('Login', Login)
Vue.component('Register', Register)
Vue.component('UpdateAccountData', UpdateAccountData)

Vue.use(Vuelidate);
Vue.use(VueI18n)
Vue.use(Notifications);
Vue.use(ElementUI);

Vue.config.productionTip = false;

import deDE from './i18n/deDE.json'

const messages = {
  de: deDE
}

const i18n = new VueI18n({
  locale: 'de',
  messages,
})

fetch(process.env.VUE_APP_API_URL + '/v1/shop')
  .then(response => response.ok ? response.json() : response)
  .then(data => {
    if (data.status === 404) {
      let div = document.getElementById('app');
      div.innerHTML += 'Shop not found';

      return
    }

    const configFeatures = {}

    data.features.forEach((feature, i) => {
      configFeatures[feature.key] = feature
    });

    const config = {
      Features: configFeatures,
      api_url: process.env.VUE_APP_API_URL
    }

    window.shopConfig = config

    let link = document.createElement( "link" )
    link.type = "text/css"
    link.rel = "stylesheet"
    link.media = "screen,print"
    /**
     * Summary: Use custom css variables
     */
    if (config.Features.CUSTOM_CSS_VARIABLES) {
      link.href = config.Features.CUSTOM_CSS_VARIABLES.data.url
    } else {
      link.href = 'http://cdn.perdia.de/assets/css/variables.css'
    }

    document.getElementsByTagName( "head" )[0].appendChild( link )

    Vue.prototype.$config = config

    new Vue({
      router,
      store,
      i18n,
      render: h => h(App),
    }).$mount('#app')
  })
