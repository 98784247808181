import axios from 'axios'
import store from '../store'

const accessToken = localStorage.getItem('accessToken')

if (accessToken) {
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
  axios.defaults.headers.common['api-consumer'] = 'shop_frontend'
}

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
})

instance.interceptors.response.use(
  res => res,
  err => {
    /**
     * Summary: Clear user session on 401
     *
     * Description: IF a accessToken is stored but invalid, clear it
     */
    if (store.state.auth.accessToken && err.response.status === 401) {
      store.dispatch('auth/clearSession')
      location.reload()
    }

    return Promise.reject(err)
  }
)

export default instance
