<template>
  <div class="login">
    <!-- Form: Login -->
    <FormGenerator v-model="model" schema="login" :errors="errors"></FormGenerator>

    <!-- CTA: Login -->
    <Button @click="submit()" class="mt-6 md:w-auto" fullsize>Anmelden</Button>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Login',
  methods: {
    ...mapActions({
      login: 'auth/login',
    }),
    submit() {
      this.login(this.model).then(() => {
        this.$emit('done')
      }).catch(err => {
        this.errors = err.response.data.errors.login
      })
    }
  },
  data() {
    return {
      model: {},
      errors: {}
    }
  }
}
</script>
