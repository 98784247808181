<template>
<div>
  <Dropdown class="action-menu__dropdown" :items="menu" :fixed="dropdownFixed">
    <img style="height: 32px;" src="https://www.flaticon.com/premium-icon/icons/svg/2985/2985030.svg">
  </Dropdown>
</div>
</template>

<script>
export default {
  name: 'NavItemSecureCheckout',
  props: {
    dropdownFixed: Boolean
  },
  computed: {
    menu() {
      return []
    },
  }
}
</script>
