<template>
  <Dropdown class="action-menu__dropdown" dropdown-key="item.key" @click="onClick">
    {{ text }}
  </Dropdown>
</template>

<script>
import {
  mapGetters
} from 'vuex'

export default {
  name: 'NavItemLink',
  props: {
    text: String,
    route: String
  },
  methods: {
    onClick() {
      if (this.route) {
        this.$router.push({ name: this.route })
      }
    }
  }
}
</script>
