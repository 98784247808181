<template>
<div class="o-action-menu" @mouseleave="dropdownActive = false">
  <template v-for="group in groups">
    <div class="action-menu__application" v-if="group.application">
      <div class="action-menu__application-name">{{ group.application.name }}</div>
    </div>

    <ul class="action-menu__group" v-if="group.items">
      <li class="action-menu__item-wrapper" v-for="item in group.items">
        <Dropdown v-if="item.dropdown" class="action-menu__item action-menu__dropdown flex-none" :items="item.dropdown.items" :dropdown-key="item.key" @click="onClickItem" @open="dropdownActive = true" @close="dropdownActive = false" :hide-mouseleave="dropdownActive"
          :show-on-hover="dropdownActive" :fixed="item.fixed" v-bind="item.dropdown.attributes">
          <!-- <Icon class="action-menu__item-icon" :icon="item.icon" v-if="item.icon"/>  -->
          <img style="height: 32px;" v-if="item.icon" :src="item.icon">
          <span class="absolute right-0 top-0 rounded-full h-4 bg-primary z-10 text-white font-bold" v-if="item.chip && item.chip.text" :id="`${item.id}-chip`"
            style="line-height: 17px;text-align: center;font-size: 11px;padding: 0 3px;width: auto;min-width: 16px;">{{ item.chip.text }}</span>
          {{ item.text }}
        </Dropdown>

        <template v-else-if="item.route">
          <router-link class="action-menu__item rounded text-primary hover:bg-gray-active" :class="menuItemClasses + ' ' + item.classes" :to="item.route">
            {{ item.text }}
          </router-link>
        </template>

        <template v-else-if="item.link">
          <a :href="item.link" target="_blank" class="action-menu__item rounded text-primary hover:bg-gray-active" :class="item.classes">
            {{ item.text }}
          </a>
        </template>

        <template v-else>
          <div class="action-menu__item rounded text-primary hover:bg-gray-active" :class="item.classes">
            {{ item.text }}
          </div>
        </template>
      </li>
    </ul>
  </template>
</div>
</template>

<script>
const MENU_ITEM_SIZES = {
  s: 'px-2',
  m: 'px-2 py-2',
};

export default {
  name: 'ActionMenu',
  props: {
    groups: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: 'm'
    }
  },
  methods: {
    onClickItem(key) {
      this.$emit(key);
      this.$emit('click', key);
    },
  },
  computed: {
    menuItemClasses() {
      return MENU_ITEM_SIZES[this.size]
    }
  },
  data() {
    return {
      dropdownActive: false,
    };
  },
};
</script>

<style lang="scss">
.o-action-menu {
    display: flex;
    height: 100%;
}

.action-menu__item {
    flex: none;
    margin-right: 4px;

    &.router-link-active {
      @apply bg-gray-active;
    }
}

.action-menu {
    &__application-name {
        line-height: 37px;
        @apply mr-4;
        @apply font-semibold;
    }
    &__group {
        display: flex;
        flex-wrap: wrap;
        overflow: auto;
        // @apply border-r;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    &__item {
        display: flex;
        align-items: center;
        line-height: 29px;
        cursor: pointer;
        padding: 0 4px;
        // @apply hover:text-black;
        @apply transition;
        @apply ease-in;
        @apply duration-100;
    }
    &__item-icon {
        @apply mr-1;
    }
}
</style>
