const setCache = (identifier, state) => {
  localStorage.setItem(identifier, JSON.stringify({data:state, updated_at:new Date()}))
}

const getCache = (identifier) => {
	if(localStorage.getItem(identifier)) {
    const item = JSON.parse(localStorage.getItem(identifier))

    if (item.data === 'true') {
      return true
    }

    if (item.data === 'false') {
      return false
    }

    return item.data
	}
}

const removeItem = (identifier) => {
  localStorage.removeItem(identifier)
}

const clearCache = (identifier) => {
  localStorage.clear(identifier)
}

export default {
  setCache,
  getCache,
  removeItem,
  clearCache
}
