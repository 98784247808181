<template>
<div class="checkout-step checkout-data">
  <!-- Shipping address -->
  <Headline title="Lieferadresse"/>

  <FormGenerator v-model="shipping_address_model" :errors="errors.shippingAddress" schema="checkoutShippingAddress"></FormGenerator>

  <!-- <FormGenerator v-if="($store.state.user.user && !$store.state.user.user.date_of_birth) || !$store.state.user.user" class="md:w-2/3" v-model="additional_data_model" :errors="errors.additional_data" schema="checkoutAdditionalData"></FormGenerator> -->

  <!-- Registration data -->
  <div v-if="mode === Modes.NEW_CUSTOMER && !fetching_order">
    Anmelde Daten

    <FormGenerator class="mb-8" v-model="register_model" :errors="errors.register" schema="checkoutRegister"></FormGenerator>
  </div>

  <div>
    <label class="cursor-pointer" style="    display: flex;
    flex-direction: row-reverse;
    align-items: center;">
      <input class="ml-2" v-model="separate_billing_address" type="checkbox"> Separate Rechnungsadresse
    </label>
  </div>

  <!-- Billing address -->
  <div class="mt-8" v-if="separate_billing_address">
    <Headline title="Rechnungsadresse" size="s"/>

    <FormGenerator v-model="billing_address_model" :errors="errors.billingAddress" schema="checkoutBillingAddress"></FormGenerator>
  </div>

  <!-- CTA: Submit data -->
  <Button class="checkout-data__submit mt-8 md:w-auto" @click="onSubmit()" :loading="submitting" v-if="!fetching_order" size="l" fullsize>
    <template v-if="mode === Modes.RETURNING_CUSTOMER">
      Speichern & zur Zahlung
    </template>
    <template v-else>
      Weiter zur Zahlung
    </template>
  </Button>
</div>
</template>

<script>
import _ from 'lodash';
import {
  mapActions,
  mapGetters,
} from 'vuex';

import cacheService from '../../../services/cache'

const Modes = {
  NEW_CUSTOMER: 'new_customer',
  RETURNING_CUSTOMER: 'returning_customer',
};

export default {
  name: 'CheckoutStepData',
  props: {
    disabled: Boolean,
    fetching_order: {
      type: Boolean,
      default: true
    }
  },
  created() {
    if (this.session_shipping_address) {
      this.shipping_address_model = this.session_shipping_address
    }
    if (this.session_billing_address) {
      this.billing_address_model = this.session_billing_address
      this.separate_billing_address = true
    }
  },
  computed: {
    ...mapGetters({
      order: 'order/getOrder',
      user: 'user/getUser',
    }),
    mode() {
      if (this.$store.state.auth.authenticated) {
        return Modes.RETURNING_CUSTOMER;
      }

      return Modes.NEW_CUSTOMER;
    },
    model() {
      return {
        ...this.register_model,
        shippingAddress: this.shipping_address_model,
        billingAddress: this.billing_address_model,
        additionalData: this.additional_data_model
      };
    },
    data() {
      return {
        register: !_.isEmpty(this.register_model) ? this.register_model : null,
        address: {
          shipping: this.shipping_address_model,
          billing: this.separate_billing_address ? this.billing_address_model : {},
        },
        additional_data: this.additional_data_model
      };
    },
    hasOrder() {
      return !_.isEmpty(this.order);
    },
  },
  watch: {
    user: {
      handler() {
        /**
         * Summary: Sets data if not present in order or no order exists.
         *
         * Description: If there is no order data, the data stored in the customer object is used to fill in the forms.
         */
        if (this.user && (!this.hasOrder || !this.order.address.shipping)) {
          if (!_.isEmpty(this.user.address.shipping)) {
            this.shipping_address_model = this.user.address.shipping;
          }
          if (!_.isEmpty(this.user.address.billing)) {
            this.billing_address_model = this.user.address.billing;
          }
        }
      },
      immediate: true,
      deep: true,
    },
    order: {
      handler() {
        if (!this.hasOrder) {
          return;
        }
        if (!_.isEmpty(this.order.address.shipping)) {
          this.shipping_address_model = this.order.address.shipping;
        }
        if (!_.isEmpty(this.order.address.billing)) {
          this.billing_address_model = this.order.address.billing;
          /**
           * Summary: Show billing address form
           *
           * Description: If there is a billing address to the order, then show the billing address form
           */
          this.separate_billing_address = true;
        }
      },
      immediate: true,
      deep: true,
    },
    shipping_address_model: {
      handler() {
        if (!_.isEmpty(this.shipping_address_model)) {
          cacheService.setCache('checkout_shipping_address_form', {...this.shipping_address_model})
        }
      },
      deep: true
    },
    billing_address_model: {
      handler() {
        if (!_.isEmpty(this.billing_address_model)) {
          cacheService.setCache('checkout_billing_address_form', {...this.billing_address_model})
        }
      },
      deep: true
    },
    separate_billing_address() {
      if (!this.separate_billing_address) {
        cacheService.removeItem('checkout_billing_address_form')
      }
    }
  },
  data() {
    return {
      register_model: {},
      additional_data_model: {},
      shipping_address_model: {},
      billing_address_model: {},
      errors: {
        register: {},
        shippingAddress: {},
        billingAddress: {},
      },
      Modes,
      separate_billing_address: false,
      submitting: false,
      session_shipping_address: cacheService.getCache('checkout_shipping_address_form'),
      session_billing_address: cacheService.getCache('checkout_billing_address_form'),
    };
  },
  methods: {
    ...mapActions({
      register: 'auth/register',
      createOrder: 'order/createOrder',
      updateOrderData: 'order/updateOrderData',
      clearBasketSession: 'basket/deleteSession',
    }),
    onSubmit() {
      if (this.submitting) {
        return
      }
      this.submitting = true
      /**
       * Summary: Create order
       *
       * Description: If (isReturningCustomer OR newCustomer) AND hasNoOrderSession => createOrder()
       */
      if ((this.mode === Modes.RETURNING_CUSTOMER || this.mode === Modes.NEW_CUSTOMER) && !this.hasOrder) {
        this.createOrder(this.data).then(() => {
          this.submitting = false
          this.$emit('done');
        }).catch((err) => {
          this.submitting = false
          /**
           * Summary: Hanlde basket_id_invalid
           *
           * Description: IF basketId is not valid, clear basket session and redirect user to basket page
           */
          if (err.data.errors.basketId === 'basket_id_invalid') {
            this.clearBasketSession();
            this.$router.push({
              name: 'basket',
            });
            return;
          }

          this.errors = err.data.errors;
        });

        return;
      }

      if (this.mode === Modes.RETURNING_CUSTOMER) {
        this.updateOrderData(this.data).then(() => {
          this.submitting = false
          this.$emit('done');
        }).catch((err) => {
          this.submitting = false
          this.errors = err.data.errors;
        });
      }
    },
  },
};
</script>
