<template>
<div style="">
  <div class="flex flex-center">
    <div class="bordesr border-gsray rounded-md p-.2 m-auto w-auto inline-block flex" style="height: 34px;width: 34px;">
      <div class="border border-white hover:border-gray rounded-md h-full cursor-pointer" style="width: 100%;">
        <div class="h-full w-full rounded-md" :style="`background: url(${value}) center center / contain no-repeat;`"></div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'FormImage',
  props: {
    value: String,
  }
}
</script>

<style lang="css" scoped>
</style>
