<template>
  <div class="register">
    <!-- Form: Login -->
    <FormGenerator v-model="model" schema="register" :errors="errors"></FormGenerator>

    <!-- CTA: Login -->
    <Button @click="submit()" class="mt-6 md:w-auto" fullsize>Registrieren</Button>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Register',
  methods: {
    ...mapActions({
      register: 'auth/register',
    }),
    submit() {
      this.register(this.model).then(() => {
        this.$emit('done')
      }).catch(err => {
        this.errors = err.response.data.errors
      })
    }
  },
  data() {
    return {
      model: {},
      errors: {}
    }
  }
}
</script>
