<template>
  <div class="payment-option px-4 pb-4">
    <div id="klarna-payments-container"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PaymentOptionKlarna',
  props: {
    data: Object
  },
  created() {
    if (this.data && this.data.client_token) {
      const client_token = this.data.client_token

      this.insertSdk().then(() => {
        Klarna.Payments.init({
          client_token
        })

        Klarna.Payments.load({
          container: '#klarna-payments-container',
          payment_method_category: this.data.payment_method_categories[0].identifier
        }, function (res) {})
      })
    }
  },
  computed: {
    ...mapGetters({
      orderBillingAddress: 'order/getOrderBillingAddress'
    }),
  },
  methods: {
    insertSdk() {
      return new Promise((resolve, reject) => {
        if (this.isSdkLoaded) {
          return
        }
        const scripts = document.getElementsByTagName('script')[0]
        const scriptElement = document.createElement('script')
        scriptElement.src = 'https://x.klarnacdn.net/kp/lib/v1/api.js'
        scriptElement.id = 'klarna-sdk'
        scripts.parentNode.insertBefore(scriptElement, scripts)
        scriptElement.onload = () => {
          resolve()
          this.isSdkLoaded = true
        }
      })
    },
    confirm() {
      return new Promise((resolve, reject) => {
        Klarna.Payments.authorize({
          payment_method_category: this.data.payment_method_categories[0].identifier
        }, {
          billing_address: {
            given_name: this.orderBillingAddress.firstname,
            family_name: this.orderBillingAddress.lastname,
            email: this.$store.state.user.user.email,
            street_address: this.orderBillingAddress.street,
            postal_code: this.orderBillingAddress.postalCode,
            city: this.orderBillingAddress.city,
            country: "DE",
            phone: this.orderBillingAddress.phone
          },
          customer: {
            date_of_birth: this.$store.state.user.user.date_of_birth,
          },
        }, (res) => {
          if (res.error) {
            return reject(res.error)
          }

          resolve(res)
        })
      })
    }
  },
  data() {
    return {
      isSdkLoaded: false
    }
  }
}
</script>
