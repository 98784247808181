<template>
<div class="c-page">
  <div class="hidden w-full border-b border-gray fixed top-0 z-10 bg-white md:flex h-16">
    <div class="w-2/12">
      <ActionMenu class="ml-2" :groups="leftMenu" @click="onClickMenuItem"></ActionMenu>
    </div>


    <div class="flex w-8/12">
      <div class="w-2/3 flex">
        <slot name="navigation"></slot>
        <ActionMenu :groups="menu" @click="onClickMenuItem"></ActionMenu>
      </div>
      <div class="w-1/3 flex justify-end text-right">
        <ActionMenu :groups="rightMenu" @click="onClickMenuItem"></ActionMenu>
      </div>
    </div>
  </div>

  <div class="w-full border-b border-gray fixed top-0 z-10 bg-white flex h-16 md:hidden">
    <div class="flex w-full justify-between">
      <div class="w-1/12 text-right flex-1">
        <ActionMenu :groups="leftMenu" @click="onClickMenuItem"></ActionMenu>
      </div>
      <div class="flex flex-1 justify-center">
        <!-- <slot name="navigation"></slot> -->
        <div class="h-12 w-12 bg-primary m-2 rounded-full"></div>
        <!-- <ActionMenu :groups="menu" @click="onClickMenuItem"></ActionMenu> -->
      </div>
      <div class="w-1/12 text-right p-2- flex flex-1 justify-end">
        <ActionMenu :groups="rightMenu" @click="onClickMenuItem"></ActionMenu>
      </div>
    </div>
  </div>

  <div class="mt-16">
    <slot name="body"></slot>
  </div>

  <div v-if="hasColSlot" class="container mx-auto flex">
    <div class="page__col page__col--left">
      <slot name="page-col-left">PAGE_COL_1</slot>
    </div>
    <div class="page__col page__col--right">
      <slot name="page-col-right">PAGE_COL_2</slot>
    </div>
  </div>

  <div class="w-full h-64 bg-black">
  </div>
</div>
</template>

<script>
import {
  mapActions,
  mapGetters
} from 'vuex'
import PageNavigation from './components/page-navigation'

export default {
  name: 'Page',
  components: {
    PageNavigation,
  },
  props: {
    headline: {
      type: String,
      default: '',
    },
    icon: {
      type: Object,
      default: () => {},
    },
    menu: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.fetchBasket()
  },
  computed: {
    ...mapGetters({
      basket: 'basket/getBasket'
    }),
    hasColSlot() {
      return this.$slots['page-col-left'] || this.$slots['page-col-right'];
    },
    optionsUser() {
      return [{
        name: 'Account',
        key: 'account',
      }, {
        name: 'Settings',
        key: 'settings',
      }];
    },
    userDropdown() {
      return [{
        key: 'logout',
        name: 'Abmelden',
        route: 'logout'
      }]
    },
    visitorDropdown() {
      return [{
        key: 'login',
        name: 'Anmelden',
      }]
    },
    rightMenu() {
      return [{
        items: [{
            name: 'link_help',
            text: 'Hilfe',
          },
          {
            name: 'admin',
            text: 'Admin',
          },
          {
            name: 'account',
            text: '',
            icon: 'https://www.flaticon.com/premium-icon/icons/svg/2984/2984967.svg',
            dropdown: this.$store.state.auth.authenticated ? this.userDropdown : this.visitorDropdown,
          }
        ]
      }];
    },
    leftMenu() {
      return [{
        items: [{
            name: 'logo',
            text: '',
            icon: 'https://www.flaticon.com/premium-icon/icons/svg/2985/2985050.svg'
          },
          {
            name: 'products',
            text: 'Produkte'
          },
          {
            name: 'orders',
            text: 'Bestellungen'
          },
          {
            name: 'shipping',
            text: 'Versand'
          },
          {
            name: 'builder',
            text: 'Builder'
          }
        ]
      }];
    }
  },
  methods: {
    ...mapActions({
      fetchBasket: 'basket/fetchBasket',
      signOut: 'auth/signOut'
    }),
    onClickMenuItem(key) {
      // make rules dynamic, to be able to configure in json
      if (key === 'logout') {
        this.signOut().then(() => {
          this.$router.push({
            name: 'shop'
          })
        })
      }
    }
  }
};
</script>

<style lang="scss">
.c-page {
    .page {
        &__menu {
            @apply mb-8;
        }
    }

    .page__col--left {
        position: fixed;
        width: 81%;
        left: 20%;
        top: 53px;
        height: calc(100% - 64px);
        bottom: 22px;
        overflow: auto;
    }

    .page__col--right {
        position: fixed;
        width: 45%;
        right: 0;
        top: 32px;
        height: calc(100% - 40px);
    }
}
</style>
