import categoriesDropdownMain from './categories-dropdown-main.json'
import footer from './footer.json'
import footerCookies from './footer-cookies.json'
import navigationDemo from './navigation-demo.json'
import navigationMain from './navigation-main.json'
import navigationMainLea from './navigation-main-lea.json'
import navigation2Rows from './navigation-2-rows.json'
import navigation_checkout from './navigation-checkout.json'
import navigationCheckoutMobile from './navigation-checkout-mobile.json'
import shop from './shop.json'
import NAVIGATION_ITEM_USER from './navigation-item-user.json'
import ACCOUNT_SETTINGS_DATA from './account-settings-data.json'
import ACCOUNT_SETTINGS_SAFETY from './account-settings-safety.json'

export default {
  categoriesDropdownMain,
  footer,
  footerCookies,
  navigationDemo,
  navigationMain,
  navigationMainLea,
  navigation2Rows,
  navigation_checkout,
  navigationCheckoutMobile,
  shop,
  NAVIGATION_ITEM_USER,
  ACCOUNT_SETTINGS_DATA,
  ACCOUNT_SETTINGS_SAFETY
}
