<template>
<AdminPage>
  <template slot="body">
    <div class="container mx-auto pb-64">
      <div class="flex border border-gray p-8">
        <div class="w-2/3">
          Shop Logo

          <FormGenerator v-model="favicon" schema="adminConfigureFavIcon"></FormGenerator>
        </div>
        <div class="w-1/3 text-left">
          <img :src="favicon.default" class="h-8">
        </div>
      </div>

      Shop config
      <div class="flex border border-gray p-4">
        <div class="w-full">
          Shop Logos

          <FormGenerator v-model="shopLogo" schema="adminConfigureShopLogo"></FormGenerator>
        </div>
      </div>

      <div class="flex border border-gray p-4 border-t-0">
        <div class="w-full">
          Icons

          <FormGenerator v-model="shopIcons" schema="adminConfigureShopIcons"></FormGenerator>
        </div>
      </div>
    </div>
    </div>
  </template>
</AdminPage>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AdminProducts',
  created() {
    this.fetchProducts()
  },
  computed: {
    ...mapGetters({
      products: 'adminProduct/getProducts'
    })
  },
  methods: {
    ...mapActions({
      fetchProducts: 'adminProduct/fetchProducts'
    }),
  },
  data() {
    return {
      favicon: {
        default: 'https://www.nike.com/android-icon-128x128.png'
      },
      shopLogo: {
        default: 'https://upload.wikimedia.org/wikipedia/commons/a/a6/Logo_NIKE.svg'
      },
      shopIcons: {
        shopping_cart: 'https://www.flaticon.com/premium-icon/icons/svg/2985/2985035.svg',
        user: 'https://www.flaticon.com/premium-icon/icons/svg/2984/2984967.svg',
        trash: 'https://www.flaticon.com/premium-icon/icons/svg/2984/2984959.svg',
        invoice: 'https://www.flaticon.com/premium-icon/icons/svg/2985/2985011.svg'
      }
    }
  }
};
</script>
