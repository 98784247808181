import Auth from './Auth';
import Confirmation from './Confirmation';
import Data from './Data';
import Payment from './Payment';

export default {
  Auth,
  Confirmation,
  Data,
  Payment
};
