<template>
<div class="checkout-step checkout-step-auth">
  <Headline>Login</Headline>

  <FormGenerator v-model="model" schema="login" :errors="errors.login"></FormGenerator>

  <Button class="checkout-auth__submit mt-6 md:w-auto" @click="onSubmit()" icon="user" :loading="submitting" fullsize>Anmelden</Button>

  <div>
    <Button @click="onStepDone()" class="mt-6 md:w-auto" fullsize>Neu Registrieren</Button>
  </div>
</div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'CheckoutAuth',
  methods: {
    ...mapActions({
      login: 'auth/login',
      fetchOrder: 'order/fetchOrder'
    }),
    onStepDone() {
      this.$emit('done')
    },
    onSubmit() {
      this.submitting = true
      this.login(this.model).then(() => {
        this.fetchingOrder = true
        this.fetchOrder().then(() => {
          this.fetchingOrder = false
          this.submitting = false
          this.onStepDone()
        }).catch(() => {
          this.fetchingOrder = false
          this.submitting = false
          this.onStepDone()
        })
      }).catch(err => {
        this.errors = err.response.data.errors
        this.submitting = false
      })
    }
  },
  data() {
    return {
      model: {},
      errors: {},
      fetchingOrder: false,
      submitting: false
    }
  }
}
</script>
