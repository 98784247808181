<template>
<div>
  <ActionMenu :groups="menu" size="s"></ActionMenu>
</div>
</template>

<script>
import {
  mapActions,
  mapGetters
} from 'vuex'

export default {
  name: 'ShopCategoriesList',
  created() {
    this.fetchCategories()
  },
  computed: {
    ...mapGetters({
      categories: 'productCategory/getCategories'
    }),
    menu() {
      return [{
        items: this.categories.map(category => ({
          name: category.key,
          text: category.name,
          route: { name: 'shop_category', params: { category: category.key } },
          classes: 'category-nav__item',
          // dropdown: {
          //   attributes: {
          //     schema: 'categoriesDropdownMain',
          //     as: 'fixed-fullsize'
          //   }
          // }
        }))
      }]
    }
  },
  methods: {
    ...mapActions({
      fetchCategories: 'productCategory/fetchCategories'
    })
  }
}
</script>
