<template>
<div class="px-4">
  <div class="flex">
    <List :items="items" title="Einstellungen" shadow/>

    <template v-if="$route.params.setting_key">
      <Card class="hidden md:block">
        <ComponentsBuilder :schema="available_settings[$route.params.setting_key]"></ComponentsBuilder>
      </Card>

      <Modal class="md:hidden" v-if="$route.params.setting_key">
        <div class="float-right" style="display: inline-block;background: #EEEEEE;border-radius: 2px;padding: 4px 8px;" @click="onCloseSetting">Schließen</div>

        <ComponentsBuilder :schema="available_settings[$route.params.setting_key]"></ComponentsBuilder>
      </Modal>
    </template>
  </div>

  <table v-if="false">
    <thead>
      <tr>
        <td class="w-1/4">E-Mail</td>
        <td>
          <div class="flex justify-between">
            {{ user.email }}
            <div class="text-gray-text text-sm">Die E-Mail Kann nicht geändert werden</div>
            <!-- <Button size="xs">Bearbeiten</Button> -->
          </div>
        </td>
      </tr>
      <tr>
        <td class="w-1/4">Passwort</td>
        <td>
          <div class="flex justify-between">
            ********
            <Button @click="onResetPassword()" size="xs">Neues Passwort anfragen</Button>
            <!-- <Button @click="updatePasswordMode = true" size="xs">Passwort ändern</Button> -->
          </div>
          <!-- <template v-if="updatePasswordMode">
            <FormGenerator v-model="newPasswordModel" :errors="errors.shippingAddress" schema="accountChangePassword"></FormGenerator>

            <Button class="mt-4">Speichern</Button>
          </template> -->
        </td>
      </tr>
      <!-- <tr>
        <td>Adresse</td>
        <td v-if="user.address.shipping" class="flex">
          <div class="w-1/2">
            <Headline size="s">Lieferadresse</Headline>
            {{ user.address.shipping.firstname }} {{ user.address.shipping.lastname }}<br>
            {{ user.address.shipping.street }},<br> {{ user.address.shipping.postalCode }} {{ user.address.shipping.city }}
          </div>

          <br><br>

          <div class="w-1/2">
            <Headline size="s">Rechnungsadresse</Headline>
            {{ user.address.shipping.firstname }} {{ user.address.shipping.lastname }}<br>
            {{ user.address.shipping.street }},<br> {{ user.address.shipping.postalCode }} {{ user.address.shipping.city }}
          </div>

          <div>
            <Button class="mt-4">Bearbeiten</Button>
          </div>
        </td>
      </tr>
      <tr v-if="user.address.billing.street">
        <td>Rechnungsadresse</td>
        <td v-if="user.address.billing">
          {{ user.address.billing.firstname }} {{ user.address.billing.lastname }}<br>
          {{ user.address.billing.street }},<br> {{ user.address.billing.postalCode }} {{ user.address.billing.city }}
        </td>
      </tr> -->
    </thead>
  </table>
</div>
</template>

<script>
import {
  mapActions,
  mapGetters
} from 'vuex';

const available_settings = {
  data: 'ACCOUNT_SETTINGS_DATA',
  safety: 'ACCOUNT_SETTINGS_SAFETY'
}

export default {
  name: 'CustomerSettings',
  computed: {
    ...mapGetters({
      user: 'user/getUser'
    }),
    navigation() {
      return {
        title: {
          text: 'Account'
        }
      };
    },
    items() {
      return [
        {
          title: 'Meine Daten',
          description: 'Adresse',
          icon: 'user',
          route: {
            name: 'customer_setting',
            params: {
              setting_key: 'data'
            }
          }
        },
        {
          title: 'Passwort & Sicherheit',
          description: 'Passwort ändern',
          icon: 'password-and-safety',
          route: {
            name: 'customer_setting',
            params: {
              setting_key: 'safety'
            }
          }
        }
      ];
    }
  },
  methods: {
    ...mapActions({
      resetPassword: 'user/resetPassword'
    }),
    onResetPassword() {
      this.resetPassword({email:this.user.email})
    },
    onCloseSetting() {
      this.$router.push({
        name: 'customer_settings'
      })
    }
  },
  data() {
    return {
      newPasswordModel: {},
      errors: {},
      updatePasswordMode: false,
      available_settings
    }
  }
};
</script>

<style lang="scss">
  .nav {
    display: flex;
    background: #fff;
    cursor: default;
    border-radius: 2px;

    &.as-vertical {
      flex-wrap: wrap;
    }
    &.with-shadow {
      @apply shadow;
    }

    li {
      line-height: 58px;
      display: flex;
      width: 100%;

      &:hover {
        cursor: pointer;
        @apply bg-gray-hover;
      }
    }
  }
</style>
