<template>
<div :class="classes" @click="onClick">
  <img v-if="url" :style="`height: ${height}px;`" :src="url">
  <Headline class="nav-item-logo__text" v-if="$config.Features.CUSTOM_SHOP_NAME" :text="$config.Features.CUSTOM_SHOP_NAME.data.name"/>
</div>
</template>

<script>
export default {
  name: 'NavItemLogo',
  props: {
    height: {
      type: String,
      default: '16'
    },
    url: String,
    route: String,
    text: String,
    classes: String
  },
  methods: {
    onClick() {
      if (this.route) {
        this.$router.push({ name: this.route })
      }
    }
  }
}
</script>

<style>
  .nav-item-logo__text {
    font-size: var(--nav-item-logo-text__font-size);
    display: var(--nav-item-logo-text__display);
  }
</style>
